<h5 class="titulo_categorias">Elige una categoría y visualiza los terapeutas especialistas.</h5>
<div class="image-wrapper my-2 pt-2" *ngIf="categories.length > 1">
    
    <mat-card [id]="'cat'+c.id" (click)="goCategorie(c.id,('cat'+c.id))" *ngFor="let c of categories"
        class="img-container ml-2 dmsans">
            <div class="gradient-overlay"></div>
            <img width="100%" mat-card-image [src]="c.image_media_id.url" (error)="setDefaultPic($event)" [alt]="c.name">
        
        <mat-card-content>
            <p class="text-center txt-main" style="color: white !important; z-index: 3 !important; position: relative;">
                {{c.name}}
            </p>
        </mat-card-content>
    </mat-card>
</div>
<div class="my-2 pt-2" *ngIf="categories.length == 1">
  <mat-card class="dmsans" [id]="'cat'+c.id" (click)="goCategorie(c.id,('cat'+c.id))" *ngFor="let c of categories"
      class="img-container ml-2 dmsans">
      <img width="100%" mat-card-image [src]="c.image_media_id.url" (error)="setDefaultPic($event)" [alt]="c.name">
      <mat-card-content>
          <p class="text-center">
              {{c.name}}
          </p>
      </mat-card-content>
  </mat-card>
</div>
<mat-divider></mat-divider>

<section class="container-fluid">
    <div class="row ">
        <!-- Doctor Info -->
        <div *ngFor="let d of doctors" class="col-xl-3 col-12 mt-3 p-2 animated fadeIn">
            <div class="row mx-0 pt-2 border card-doctor">
                <div class="col-4">
                    <div class="avatar-container">`
                        <img [src]="d.photo_media_id ? d.photo_media_id.url : './assets/img/profile.jpg'" (error)="setDefaultPic($event)" width="100%" alt="avatar">
                    </div>
                </div>
                <div class="col-8">
                    <!--<p class="m-0 text-secondary"> {{d.services[0].name}} </p>-->
                    <p class="m-0"> <b> {{d.first_name}} {{d.last_name}} </b> </p>
                    <!--<div class="star-container">
                        <mat-icon class="text-warning">star</mat-icon>
                        <mat-icon class="text-secondary ml-2">start</mat-icon>
                        <span class="text-secondary ml-2"> {{d.score || '0'}} </span>
                    </div>-->
                </div>
                <div class="col-12 row mt-3">
                    <!--<div class="col">
                        <p class="txt-main text-center" style="color: #084464 !important;"> {{d.total_reviews || '0'}} reseña </p>
                    </div>-->
                    <div class="col">
                        <p class="txt-secondary text-center" style="color: #084464 !important;"> {{ formatDate(d.working_since) }} de experiencia </p>
                    </div>
                </div>
                <div class="col-12">
                    <p class="text-dark text-truncate" [matTooltip]="d.user_description"> {{d.user_description}}
                    </p>
                    <!--<a class="txt-main cursor" *ngIf="d.resume_media_id" [href]="d.resume_media_id.url" target="_blank"> Ver Curriculum </a>-->
                </div>
                <div class="col-12 row my-3">
                    <a class="icon-doctor col-auto ml-3" style="background-color: darkgray !important; margin-left: 10px;" (click)="bookingNow(d.id)" [matTooltip]="'Ver Perfil'" >
                        <mat-icon class="text-white">person</mat-icon>
                    </a>
                    <button class="icon-doctor col-auto ml-3" style="background-color: darkgray !important;" *ngIf="d.resume_media_id" (click)="openPdfModal(d.resume_media_id.url)" target="_blank" [matTooltip]="'Ver CV'" >
                        <mat-icon class="text-white">edit_document</mat-icon>
                    </button>
                    
                </div>
                <button mat-button class="bg-main text-white mb-3 book-btn" style="background-color: #084464 !important;"
                    (click)="bookingNow(d.id)"> Agendar videollamada
                </button>
            </div>

        </div>
    </div>
</section>

<!-- filter bar -->
<div class="icons-bar" style="background-color: #084464 !important;">
    <button mat-icon-button (click)="reset()" [matTooltip]="'Actualizar terapeutas'">
        <mat-icon>replay</mat-icon>
    </button>
    <button mat-icon-button (click)="openFilters()" [matTooltip]="'Filtrar terapeutas'">
        <mat-icon>tune</mat-icon>
    </button>
</div>