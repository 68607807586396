<form [formGroup]="registerFG" (ngSubmit)="register()">
    <div class="row justify-content-center mt-5">
        <div class="col-xl-12">
            <mat-form-field class="full-width">
                <input matInput formControlName="name" required placeholder="Nombre">
            </mat-form-field>
        </div>
        <div class="col-xl-6">
            <mat-form-field class="full-width">
                <input matInput type="phone" formControlName="phone" required placeholder="Celular">
            </mat-form-field>
        </div>
        <div class="col-xl-6">
            <mat-form-field class="full-width">
                <input matInput type="email" formControlName="email" required placeholder="Email">
            </mat-form-field>
        </div>
        <div class=" col-12">
            <mat-form-field class="full-width">
                <textarea matInput type="email" formControlName="description" placeholder="Asunto" required cols="30"
                    rows="10"></textarea>
            </mat-form-field>
        </div>

        <!-- Submit Button -->
        <div class="col-xl-7 col-12">
            <button [disabled]="!registerFG.valid" type="submit" class="full-width bg-main text-white btn">
                Enviar
            </button>
        </div>
    </div>
</form>