<nav class="navbar navbar-light bg-light">
    <a class="navbar-brand">
        <img src="./assets/logo.svg" width="30" height="30" alt="logo">
        <span> MindHelp </span>
    </a>
</nav>
<section class="container">
    <h3 class="txt-main mt-3"> MindHelp política de privacidad </h3>
    <p> <b> Sobre tu privacidad y nuestra política </b> </p>
    <div class="ml-4">
        <p>
            <b> Tu privacidad es lo más importante para nosotros.</b> Muchos de nuestros esfuerzos,
            planes, herramientas, procesos y recursos son enfocados en este sentido. Este
            documento es nuestra “Política de privacidad” y contiene los detalles de asuntos
            relacionados con tu privacidad al usar nuestra plataforma. <br><br>
            La política de privacidad es parte de nuestros “Términos y condiciones” que pueden
            encontrarse en nuestra app. Cuando usas nuestra plataforma Mind Help aceptas y estás
            de acuerdo con nuestros términos y condiciones y con nuestra política de privacidad, si
            por alguna razón no estás de acuerdo con nuestra política no debes usar nuestra
            plataforma. Al ingresar y hacer uso de nuestra plataforma se entiende que has leído,
            entendido y que estás de acuerdo con los términos y condiciones de uso y con nuestra
            política de privacidad.
        </p>
        <p> <b> Uso, recolección y revelación de información </b> </p>
        <p>
            Para poder hacer uso correcto y ordenado de nuestra plataforma, incluido los servicios
            de video-terapia necesitamos contar con algún tipo de información personal que te
            identifique (nombre, teléfono, correo, edad, dirección, información de pago o facturación,
            tarjeta de crédito o débito, entre otras posibles). Es tu decisión cuál información nos
            compartes, sin embargo, algunas funciones de la plataforma pueden no funcionar si no
            contamos con la información necesaria. Al compartir con nosotros la información estás
            de acuerdo con nuestros métodos de recolección y uso de información y otros términos
            mencionados en la política de privacidad.
        </p><br>
        <p>
            <b>
                Proteger tu información en prioritario para nosotros. Nunca venderemos o
                rentaremos ninguna clase de información contenida en nuestra plataforma. Nunca
                usaremos o revelaremos ninguna clase de información a menos que tu
                específicamente y explícitamente lo requieras.
            </b>
        </p>
        <p>
            La información podría usarse para los siguiente: <br>
            o Crear tu cuenta de ingreso a la plataforma Mind Help.<br>
            o Para manejar tu cuenta, darte servicio como cliente y asegurar la calidad del
            servicio.<br>
            o Para contactarte o proporcionarte información, alertas o sugerencias
            relacionadas con nuestros servicios.<br>
            o Para temas de facturación.<br>
            o Para contactarte nosotros o alguna autoridad si es que existe alguna buena
            razón que nos haga pensar que tu o alguien más puedas estar en peligro.<br>
            o Para contactarte y facilitarte con un terapeuta.<br>
            o Para supervisar, monitorear y mejorar los servicios.<br>
            o Para comercializar mas y mejores servicios de nuestra plataforma contigo.
        </p>
    </div>
    <p> <b> Cookies y señales web </b> </p>
    <div class="ml-4">
        <p>
            Algunas veces podríamos llegar a usar “cookies” o “señales web” para obtener algún
            tipo de información. Las usamos para permitir algunas operaciones técnicas de nuestra
            plataforma. Puedes cambiar la configuración de tu página para que no te lleguen estos
            cookies, sin embargo, puede existir el riesgo que no te permita usar la plataforma.
        </p>
    </div>
    <p> <b> Herramientas sociales e información general </b> </p>
    <div class="ml-4">
        <p>
            Usamos varias herramientas públicas como blogs, redes sociales, etc. Cualquier
            información que compartas en estos medios podríamos leerla o usarla dentro de las
            limitantes de nuestra política de privacidad.
        </p>
    </div>
    <p> <b> Phishing </b> </p>
    <div class="ml-4">
        <p>
            El robo de identidad online, incluido la práctica llamada “Phising” son de gran
            preocupación. Debes ser siempre muy cuidadoso al compartir la información de tu
            cuenta y siempre hacerlo dentro de nuestro sistema. Nunca te pediremos tu cuenta de
            login o número de tarjeta de crédito en ningún sistema fuera de nuestra plataforma o
            comunicación no segura (email, teléfono o otros).
        </p>
    </div>
    <p> <b> Seguridad </b> </p>
    <div class="ml-4">
        <p>
            Nuestros sistemas y tecnología están diseñados tomando en cuenta nuestra política de
            privacidad y seguridad. Aplicamos y superamos las mejores prácticas de la industria
            para prevenir cualquier acceso o filtración no autorizado.
        </p>
    </div>
    <p> <b> Menores de edad </b> </p>
    <div class="ml-4">
        <p>
            No solicitamos ni requerimos información de menores de edad. Mind Help puede
            atender menores de edad con el permiso por escrito de sus padres o tutores.
        </p>
    </div>
    <p> <b> Complimiento de la ley y la autoridad </b> </p>
    <div class="ml-4">
        <p>
            Nosotros cooperamos en las autoridades en caso de ser necesario. Nuestros terapeutas
            están obligados a revelar información a las autoridades correspondientes en caso de
            detectar algún tipo de abuso a un menor de edad o adulto vulnerable, riesgo potencial
            de suicidio, amenaza de daño a otra persona.
            <br><br>
            <b> 2. Cambios a nuestra política de privacidad </b> <br><br>
            Podemos cambiar o actualizar esta política de acuerdo a nuestras necesidades. La
            fecha de la última revisión aparece al inicio de este documento. Le invitamos a que
            revise periódicamente este documento para estar al tanto de sus actualizaciones. Sin
            importar los cambios y actualizaciones en nuestra política de privacidad, nunca
            usaremos la información proporcionada por nuestros usuarios sin antes avisarles y
            otorgarles el derecho a dejar de usar Mind Help.
            <br><br>
            <b> 3. Contacto Mind Help </b>
            <br><br>
            Cualquier pregunta sobre nuestra política de privacidad o cualquier otro asunto puede
            enviarnos un correo a través de nuestra forma de contacto en www.mindhelp.mx
        </p>
    </div>

</section>
<footer class="bg-main">

</footer>