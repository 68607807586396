<mat-drawer-container class="full-scroll">
    <!-- Side nav -->
    <mat-drawer class="o-h" mode="side" [opened]="openMenu">
        <img alt="logo" src="./assets/img/logo_en_chiquito.png" class="m-2" width="75px" />
        <!--<div class="top-space"></div>-->
        <mat-divider></mat-divider>
        <div class="m-2 text-center">
            <a [routerLink]="['home']" routerLinkActive="txt-main">
                <mat-icon>label_outline</mat-icon>
                <p>Inicio</p>
            </a>
        </div>
        <mat-divider></mat-divider>
        <div class="m-3 text-center">
            <a [routerLink]="['booking']" routerLinkActive="txt-main">
                <mat-icon>calendar_today_outline</mat-icon>
                <p>Sesiones</p>
            </a>
        </div>
        <!--YA NO SE NECESITA-->
        <!--<mat-divider></mat-divider>
        <div class="m-2 text-center">
            <a [routerLink]="['chat']" routerLinkActive="txt-main">
                <mat-icon>chat_bubble_outline</mat-icon>
                <p>Chat</p>
            </a>
        </div>-->
        <!--COMENTADO PARA PRODUCCIÓN-->
        <!--<mat-divider></mat-divider>
        <div class="m-2 text-center">
            <a [routerLink]="['recipes']" routerLinkActive="txt-main">
                <mat-icon>event_note</mat-icon>
                <p>Recetas</p>
            </a>
        </div>-->
        <mat-divider></mat-divider>
        <div class="m-2 text-center">
            <a [routerLink]="['settings']" routerLinkActive="txt-main">
                <mat-icon>settings_applications_outline</mat-icon>
                <p>Ajustes</p>
            </a>
        </div>
        <mat-divider></mat-divider>
        <div class="m-2 text-center">
            <a class="cursor" (click)="logout()">
                <mat-icon>input</mat-icon>
                <p> Salir </p>
            </a>
        </div>
        <mat-divider></mat-divider>
    </mat-drawer>
    <mat-drawer-content>
        <!-- Navbar -->
        <mat-toolbar class="bg-main text-white fixed" style="background-color: #084464 !important;">
            <button mat-button (click)="openMenu=!openMenu" [matTooltip]="'Mostrar/Ocultar menú'">
                <mat-icon *ngIf="!openMenu">menu</mat-icon>
                <mat-icon *ngIf="openMenu">menu_open</mat-icon>
            </button>
            <h3 class="dmsans hide-on-non-phone"> {{titleNav}} </h3>
        </mat-toolbar>
        <!-- Aplication -->
        <section class="content-app">
            <router-outlet></router-outlet>
        </section>
    </mat-drawer-content>
</mat-drawer-container>

<div class="loading" *ngIf="isLoading" >
    <mat-spinner></mat-spinner>
</div>
