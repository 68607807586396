<h2 class="bg-main p-2 text-white"> Dejar una reseña </h2>
<div class="row m-0 p-2">
    <fieldset class="rating col-12">
        <input type="radio" [name]="inputName" value="5" [checked]="rating===5" />
        <label title="Rocks!" (click)='onClick(5)'>5 stars</label>
    
        <input type="radio" [name]="inputName" value="4" [checked]="rating===4" />
        <label title="Pretty good" (click)='onClick(4)'>4 stars</label>
    
        <input type="radio" [name]="inputName" value="3" [checked]="rating===3" />
        <label title="Meh" (click)='onClick(3)'>3 stars</label>
    
        <input type="radio" [name]="inputName" value="2" [checked]="rating===2" />
        <label title="Kinda bad" (click)='onClick(2)'>2 stars</label>
    
        <input type="radio" [name]="inputName" value="1" [checked]="rating===1" />
        <label title="Sucks big time" (click)='onClick(1)'>1 star</label>
    </fieldset>
    
    <mat-form-field class="col-12">
        <textarea matInput [(ngModel)]="review" placeholder="Comentario"></textarea>
    </mat-form-field>
</div>

<mat-dialog-actions>
    <button mat-button mat-dialog-close>Cancelar</button>
    <!-- The mat-dialog-close directive optionally accepts a value as a result for the dialog. -->
    <button mat-button (click)="send()">Enviar</button>
</mat-dialog-actions>