<section class="container-fluid">
    <!-- ng for tarejtas guardadas -->
    <div class="row">
        <div class="col-12 border border-secondary row align-items-center py-3 m-0 mb-3" *ngFor="let c of savedCards" >
            <div class="col-2">
                <img  *ngIf="c.brand == 'amex'" src="./assets/img/amex.png" width="100%" alt="amex">
                <img *ngIf="c.brand == 'mastercard'" src="./assets/img/mastercard.jpg" width="100%" alt="mastercard">
                <img *ngIf="c.brand == 'visa'" src="./assets/img/visa.svg" width="100%" alt="visa">
            </div>
            <div class="col-8">
                {{c.card_number}} | exp: {{c.expiration_month}} / {{ c.expiration_year }}
            </div>
            <div class="col">
                <button class="btn btn-danger" (click)="deleteCard(c.id)" > Borrar </button>
            </div>
        </div>
    </div>
    <div class="cards-container"></div>
    <div class="row justify-content-center">
        <button (click)="openDialog()" class="col-auto" mat-button> Agregar Tarjeta <mat-icon> payment</mat-icon> </button>
    </div>
    <div style="display: flex; flex-direction: row; position: absolute; bottom: 10px;">
        <div style="display: flex; flex-direction: column; margin-right: 25vw;">
            Se acepta:
            <div>
                <img src="../../../assets/img/mastercard.jpg" alt="OpenPay logo" style="height: 50px; width: auto; margin-left: 10px;">
                <img src="../../../assets/img/amex.png" alt="OpenPay logo" style="height: 50px; width: auto; margin-left: 10px;">
                <img src="../../../assets/img/visa.svg" alt="OpenPay logo" style="height: 50px; width: auto; margin-left: 10px;">
            </div>
        </div>
        <div style="display: flex;">
            <img src="../../../assets/img/LogotipoOpenpay-01.jpg" alt="OpenPay logo" style="height: auto; width: 260px;">
        </div>
    </div>
</section>

<div class="loading" *ngIf="isLoading">
    <mat-spinner></mat-spinner>
</div>