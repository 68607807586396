<section class="container-fluid pt-3 max-he over-h">

  <section class="container-fluid max-he" *ngIf="chatList.length > 0; else loggedOut">
    <!-- chat section -->
    <div class="row chat-container max-he">

      <!-- select chat section -->
      <div class="col-xl-3 p-0 border-right max-he">
        <div [id]="'chat'+c.id" class="chat-card border-bottom p-2" [ngClass]="{'selected': curentChatId == c.id}"
          (click)="getChatHistory(c)" *ngFor="let c of chatList">

          <div class="img-container mr-2">
            <img width="100%" [src]="c.doctor_user_id.photo_media_id" [alt]="c.doctor_user_id.first_name">
          </div>

          <div class="message-container">
            <div class="text">
              <p class="text-dark m-0"> <b> {{c.doctor_user_id.first_name}} {{c.doctor_user_id.last_name}} </b> </p>
              <span> {{c.updated_at | date:'short'}} </span>
            </div>

            <div class="message">
              <p class="text-dark m-0 text-truncate"> {{c.message}} </p>
            </div>
          </div>

        </div>

      </div>
      <!-- end select chat section -->

      <!-- message section -->
      <div *ngIf="curentChatId != 0" class="col p-0 chat max-he">
        <div class="chat-history">
          <div class="chat-message" *ngFor="let ch of chatHistory">
            <p class="message-chat row mx-0"
              [ngClass]="{'doctor-message': ch.sent_by_user_id.id != userID, 'user-message': ch.sent_by_user_id.id == userID}">
              <span class="col-10"> {{ch.message}} </span>
              <!-- date -->
              <span class="small text-secondary col-2"> {{ ch.updated_at | date:'shortTime' }} </span>
            </p>
          </div>
        </div>
        <div class="chat-input pt-2">
          <form (ngSubmit)="onSubmit()">
            <button class="ml-3">
              <mat-icon matTooltip="Subir Archivo" class="txt-main">add</mat-icon>
            </button>
            <input type="text" [(ngModel)]="chatInput" name="chatInput" placeholder="Escribe un mensaje aquí">
            <button type="submit" class="ml-3">
              <mat-icon class="txt-main">send</mat-icon>
            </button>
          </form>
        </div>
      </div>
      <!-- end message section -->
      <div *ngIf="curentChatId == 0" class="col p-0 chat">
        <h3 class="text-center text-secondary"> Seleccione un chat para comenzar </h3>
      </div>
    </div>
    <!-- end chat section -->

  </section>

  <ng-template #loggedOut>
    <h3 class="text-center text-secondary"> Por el momento no hay chats disponibles </h3>
  </ng-template>

</section>