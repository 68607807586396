import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';

@Component({
  selector: 'app-pdf-modal',
  templateUrl: './pdf-modal.component.html',
  styleUrls: ['./pdf-modal.component.scss']
})
export class PdfModalComponent {
  sanitizedPdfLink: SafeResourceUrl;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { pdfLink: string },
    private sanitizer: DomSanitizer
  ) {
    // Usa DomSanitizer para hacer la URL del PDF segura
    this.sanitizedPdfLink = this.sanitizer.bypassSecurityTrustResourceUrl(data.pdfLink);
  }
}
