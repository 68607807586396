<h1 mat-dialog-title class="text-center border-bottom p-3" >Agregar Tarjeta</h1>
<div mat-dialog-content class="m-0">

    <form [formGroup]="cardFG" class="col-12 row m-0 p-0" id="cardForm">
        <label *ngIf="service.user.account_status=='plan_unpaid'">Es necesario agregar una tarjeta para poder activar el plan requerido</label>
        <mat-form-field class="col-12">
            <input matInput formControlName="name" required placeholder="Nombre en la Tarjeta">
            <div
            style="margin-right: 10px;"
            *ngIf="
            cardFG.controls['name'].hasError('minlength') ||
            cardFG.controls['name'].hasError('required') &&
            cardFG.controls['name'].touched"
            class="error-modal">
            El nombre debe tener mínimo 3 letras.
            </div>
        </mat-form-field>
        <mat-form-field class="col-9">
            <input matInput formControlName="number" required placeholder="Número de Tarjeta" min="16" max="16">
            <div
            style="margin-right: 10px;"
            *ngIf="
            cardFG.controls['number'].hasError('minlength') ||
            cardFG.controls['number'].hasError('maxlength') ||
            cardFG.controls['number'].hasError('pattern') ||
            cardFG.controls['number'].hasError('required') &&
            cardFG.controls['number'].touched"
            class="error-modal">
            La tarjeta debe tener 16 dígitos.
            </div>
        </mat-form-field>
        <mat-form-field class="col-3">
            <input matInput formControlName="cvc" required placeholder="cvv">
            <div
            style="margin-right: 10px;"
            *ngIf="
            cardFG.controls['cvc'].hasError('minlength') ||
            cardFG.controls['cvc'].hasError('maxlength') ||
            cardFG.controls['cvc'].hasError('pattern') ||
            cardFG.controls['cvc'].hasError('required') &&
            cardFG.controls['cvc'].touched"
            class="error-modal">
            El CVC debe tener 3 o 4 dígitos.
            </div>
        </mat-form-field>
        <mat-form-field class="col-4">
            <input matInput formControlName="exp_month" required placeholder="mes de vencimiento">
            <div
            style="margin-right: 10px;"
            *ngIf="
            cardFG.controls['exp_month'].hasError('minlength') ||
            cardFG.controls['exp_month'].hasError('maxlength') ||
            cardFG.controls['exp_month'].hasError('pattern') ||
            cardFG.controls['exp_month'].hasError('required') &&
            cardFG.controls['exp_month'].touched"
            class="error-modal">
            El mes de expiración debe tener dos dígitos.
            </div>
        </mat-form-field>
        <mat-form-field class="col-4">
            <input matInput formControlName="exp_year" required placeholder="año de vencimiento">
            <div
            style="margin-right: 10px;"
            *ngIf="
            cardFG.controls['exp_year'].hasError('minlength') ||
            cardFG.controls['exp_year'].hasError('maxlength') ||
            cardFG.controls['exp_year'].hasError('pattern') ||
            cardFG.controls['exp_year'].hasError('required') &&
            cardFG.controls['exp_year'].touched"
            class="error-modal">
            El año de expiración debe tener dos dígitos.
            </div>
        </mat-form-field>
        <mat-form-field class="col-4">
            <input matInput formControlName="zip" required placeholder="codigo postal">
            <div
            style="margin-right: 10px;"
            *ngIf="
            cardFG.controls['zip'].hasError('minlength') ||
            cardFG.controls['zip'].hasError('maxlength') ||
            cardFG.controls['zip'].hasError('pattern') ||
            cardFG.controls['zip'].hasError('required') &&
            cardFG.controls['zip'].touched"
            class="error-modal">
            El código postal debe tener 5 dígitos.
            </div>
        </mat-form-field>
        <mat-form-field class="col-12">
            <input matInput formControlName="street1" required placeholder="Dirección">
            <div
            style="margin-right: 10px;"
            *ngIf="
            cardFG.controls['street1'].hasError('minlength') ||
            cardFG.controls['street1'].hasError('required') &&
            cardFG.controls['street1'].touched"
            class="error-modal">
            La dirección debe tener mínimo 3 letras.
            </div>
        </mat-form-field>
        <mat-form-field class="col-12">
            <input matInput formControlName="street2" required placeholder="Colonia">
            <div
            style="margin-right: 10px;"
            *ngIf="
            cardFG.controls['street2'].hasError('minlength') ||
            cardFG.controls['street2'].hasError('required') &&
            cardFG.controls['street2'].touched"
            class="error-modal">
            La colonia debe tener mínimo 3 letras.
            </div>
        </mat-form-field>
        <mat-form-field class="col-4">
            <input matInput formControlName="city" required placeholder="Ciudad">
            <div
            style="margin-right: 10px;"
            *ngIf="
            cardFG.controls['city'].hasError('minlength') ||
            cardFG.controls['city'].hasError('required') &&
            cardFG.controls['city'].touched"
            class="error-modal">
            La ciudad debe tener mínimo 3 letras.
            </div>
        </mat-form-field>
        <mat-form-field class="col-4">
            <input matInput formControlName="state" required placeholder="Estado">
            <div
            style="margin-right: 10px;"
            *ngIf="
            cardFG.controls['state'].hasError('minlength') ||
            cardFG.controls['state'].hasError('required') &&
            cardFG.controls['state'].touched"
            class="error-modal">
            El estado debe tener mínimo 3 letras.
            </div>
        </mat-form-field>
        <mat-form-field class="col-4">
            <input matInput formControlName="country" required placeholder="País">
            <div
            style="margin-right: 10px;"
            *ngIf="
            cardFG.controls['country'].hasError('minlength') ||
            cardFG.controls['country'].hasError('required') &&
            cardFG.controls['country'].touched"
            class="error-modal">
            El país debe tener mínimo 3 letras.
            </div>
        </mat-form-field>
        <div mat-dialog-actions align="end" >
            <button mat-button (click)="onNoClick()">Cancelar</button>
            <button mat-button [disabled]="cardFG.invalid" (click)="saveCard()" > Guardar </button>
        </div>
    </form>

  </div>

  <div class="loading" *ngIf="isLoading">
    <mat-spinner></mat-spinner>
  </div>

  <div style="position: absolute; bottom: 10px; left: 10px; display: flex; flex-direction: column;">
    Se acepta:
    <div>
        <img src="../../../assets/img/mastercard.jpg" alt="OpenPay logo" style="height: 50px; width: auto; margin-left: 10px;">
        <img src="../../../assets/img/amex.png" alt="OpenPay logo" style="height: 50px; width: auto; margin-left: 10px;">
        <img src="../../../assets/img/visa.svg" alt="OpenPay logo" style="height: 50px; width: auto; margin-left: 10px;">
    </div>
  </div>
  <div style="position: absolute; bottom: 10px; right: 10px;">
    <img src="../../../assets/img/LogotipoOpenpay-01.jpg" alt="OpenPay logo" style="height: auto; width: 260px;">
  </div>