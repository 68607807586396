<nav class="navbar navbar-light bg-main" style="background-color: white !important;">
    <a class="navbar-brand">
        <img src="./assets/img/40_anos(1).jpeg" height="70" alt="" style="margin-left: 20px;">
    </a>
</nav>

<div class="login-box border shadow rounded mt-5 animated pulse">
    <div class="row border-bottom col-12 text-center p-3">
        <div class="col-12 text-center">
            <img class="logo" src="./assets/img/logo_en_chiquito.jpeg" alt="logo">
        </div>
    </div>
    <form [formGroup]="registerFG" class="col-12" (ngSubmit)="register()">
        <div class="login-container">
            <div class="col-xl-6">
                <mat-form-field class="full-width">
                    <input matInput formControlName="first_name" required placeholder="Nombre">
                </mat-form-field>
            </div>
            <div class="col-xl-6">
                <mat-form-field class="full-width">
                    <input matInput formControlName="last_name" required placeholder="Apellido">
                </mat-form-field>
            </div>
            <div class="col-xl-6 col-12">
                <mat-form-field class="full-width">
                    <input matInput type="email" formControlName="email" required placeholder="Email">
                </mat-form-field>
            </div>
            <div class="col-xl-6 col-12">
                <mat-form-field class="full-width">
                    <input matInput formControlName="password" required placeholder="Contraseña"
                        [type]="hide ? 'password' : 'text'">
                    <button mat-icon-button matSuffix (click)="hide = !hide" [attr.aria-label]="'Hide password'"
                        [attr.aria-pressed]="hide">
                        <mat-icon>{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
                    </button>
                </mat-form-field>
            </div>

            <div class="col-xl-6 col-12">
                <mat-form-field class="full-width">
                    <input matInput type="tel" formControlName="phone_number" required placeholder="Número Celular">
                </mat-form-field>
            </div>

            <div class="col-xl-6 col-12">
                <mat-form-field class="full-width">
                    <mat-label>Pais</mat-label>
                    <mat-select formControlName="country" required>
                        <mat-option value="MX">
                            Mexico
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>

            <div class="col-6 " (click)="pickerc.open()" >
                <button mat-button type="button" class="txt-main" >
                    <mat-icon>calendar_today_outline</mat-icon>
                </button>
                <mat-form-field>
                    <input matInput [max]="maxDate" name="date" [matDatepicker]="pickerc" (dateChange)="dateNow($event.value)" disabled
                        placeholder="Fecha de nacimiento">
                    <mat-datepicker disabled=false startView="multi-year" #pickerc></mat-datepicker>
                </mat-form-field>
            </div>

            <div class="col-xl-6 col-12">
                <mat-form-field class="full-width">
                    <mat-label>Genero</mat-label>
                    <mat-select formControlName="gender" required>
                        <mat-option value="Male">
                            Masculino
                        </mat-option>
                        <mat-option value="Female">
                            Femenino
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>

            <div class="col-12" *ngIf="suscription && suscription !== '0'">
              <mat-form-field class="full-width">
                  <mat-label>Subscripción</mat-label>
                  <mat-select formControlName="suscription">
                    <mat-option *ngFor="let item of subscriptions" value="{{item.id}}">
                      {{item.name}} &nbsp;&nbsp;(<small>{{item.description}}</small>)
                    </mat-option>
                      <!--<mat-option value="1">
                        <b>Paquete 1</b> : <small>1 sesión de video terapia</small>
                      </mat-option>
                      <mat-option value="2">
                        <b>Paquete 2</b> : <small>3 sesiones de video terapia</small>
                      </mat-option>
                      <mat-option value="3">
                        <b>Paquete 3</b> : <small>4 sesiones de video terapia</small>
                    </mat-option>-->
                  </mat-select>
                  <!--<mat-hint>Texto Ilimitado por 5 días</mat-hint>-->
              </mat-form-field>
          </div>

            <!-- Submit Button -->
            <div class="col-12 p-3 justify-content-around">
              <button [disabled]="!registerFG.valid" type="submit" class="full-width bg-main text-white" mat-button>Registrate</button>
              <!-- <button [disabled]="!registerFG.valid" type="submit" class="full-width bg-main text-white" mat-button>Iniciar sesión</button> -->
              <p class="full-width text-center mt-2"> ¿Ya tienes cuenta?
                <a class="txt-main" [routerLink]="['/login']"> Iniciar sesión </a>
              </p>
            </div>
            <!-- stores buttons -->
            <div class="col-12 mb-3 row justify-content-around">
                <!--<a href="https://play.google.com/store/apps/details?id=com.mindhelp.pacient&hl=en" target="_blank">
                    <img src="./assets/img/Playstore.png" alt="playstore">
                </a>
                <a href="https://play.google.com/store/apps/details?id=com.mindhelp.pacient&hl=en" target="_blank">
                    <img src="./assets/img/Appstore.png" alt="appstore">
                </a>-->
            </div>
        </div>
    </form>

</div>
