<div class="todo">


<div style="background-color: transparent; width: 100%; height: 12vh; padding: 15px; flex-direction: row; display: flex;">
    <div style="height: 100%; width: 33%; display: flex; flex-direction: row;">
        <img src="../../assets/img/logo_gnp.png" style="width: auto; height: 100%; padding-right: 15px; border-right-width: 1px; border-right-style: solid; border-right-color: rgb(130, 130, 130);"/>
        <img src="../../assets/img/logo_prisma.png" style="width: auto; height: 80%; margin-left: 15px; margin-top: 5px;"/>
    </div>
    <div style="height: 100%; width: 33%; display: flex; align-items: center; align-content: center;"><span style="text-align: center; width: 100%; font-size: 2rem;"></span></div>
    <div style="height: 100%; width: 33%; text-align: right;">
        </div>
</div>

<section class="banner">
    <!--<nav class="navbar navbar-expand-lg navbar-light py-4">
        <a class="navbar-brand"><img src="./assets/Mindhelp.svg" alt="" class="img-responsive"></a>
        <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
            <span class="navbar-toggler-icon"></span>
        </button>

        <div class="collapse navbar-collapse" id="navbarSupportedContent">
        </div>
    </nav>-->

    

    <div class="info-banner d-none d-xl-flex" style="margin-left: 60px;">
        <h2 class="text-dark epilogue " style="color: #023171 !important;"> <b> ¡Estamos contigo! </b> 
        </h2>
        <p style="color: #023171 !important; font-size: 20px;">
          Tu salud mental y emocional son<br>
          aspectos fundamentales que<br>
          contribuyen a tu bienestar.<br><br>
          Nuestros terapeutas están para<br>
          ayudarte, solo agenda tu<br>
          videollamada el día y horario <br>
          de tu preferencia.
        </p>
    </div>

    <div class="login-section col-xl-4 col-md-6 col-sm-8 col-12 align-self-center">
        <app-login></app-login>
    </div>

</section>


<!--<section class="banner">
    <nav class="navbar navbar-expand-lg navbar-light py-4">
        <a class="navbar-brand"><img src="./assets/img/logo-landing.png" alt="" class="img-responsive"></a>
        <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
            <span class="navbar-toggler-icon"></span>
        </button>

        <div class="collapse navbar-collapse" id="navbarSupportedContent">-->


            <!-- <ul class="navbar-nav mr-auto">
                <li class="nav-item">
                    <a class="nav-link text-white cursor" [ngx-scroll-to]="padecimiento" > Padecimientos </a>
                </li>
                <li class="nav-item">
                    <a class="nav-link text-white cursor" [ngx-scroll-to]="descarga" > Descargar App </a>
                </li>
            </ul> -->
        
        
        <!--</div>
    </nav>

    <div class="info-banner col-xl-6 col-12 d-none d-xl-flex">
        <h1 class="text-white raleway title-landing"> <b> ¿Mal día? </b> <br> <span class="semi-bolt"> Estamos
                <span class="green-btm">contigo</span> </span>
        </h1>
        <p class="text-white info-txt my-5 raleway">
          Con Mind Help puedes elegir tu sesión con los<br>
          mejores psicólogicos, a la hora que decidas, desde<br>
          el lugar que más te guste, en total privacidad.
        </p>
    </div>

    <div class="login-section col-xl-4 col-md-6 col-sm-8 col-11 align-self-center">
        <app-login></app-login>
    </div>

</section>-->

<!-- <section class="container my-5 py-5" #padecimiento>
    <h1> <b> ¿Qué retos enfrentas hoy? </b> </h1>
    <div class="row my-5">
        <div *ngFor="let c of categorias" class="col-xl-3 col-6">
            <div class="mini-card shadow my-3">
                <div class="img-mini">
                    <img width="100%" [src]="c.image_media_id.url" alt="c.name">
                </div>
                <div class="mini-text">
                    <p class="text-secodnary m-0"> {{c.name}} </p>
                </div>
            </div>
        </div>
    </div>
</section>

<section class="container mt-5" #descarga>
    <div class="row">
        <div class="col-xl-6">
            <h2 class="raleway title-landing"> Descarga la App </h2>
            <p class="text-secondary info-txt raleway">
                Agenda citas con los mejores psicólogos, <br>
                ten video-sesiones y cambia tu vida.
            </p>
            <p class="text-dark info-txt raleway">
                <b>
                    Creemos en el potencial del ser <br>
                    humano y la felicidad de vivir.
                </b>
            </p>
            <a href="https://play.google.com/store/apps/details?id=com.mindhelp.pacient&hl=en" target="_blank" >
                <img src="./assets/img/Playstore.png" alt="playstore">
            </a>
            <a href="https://apps.apple.com/mx/app/mindhelp-paciente/id1503859820" target="_blank" >
                <img src="./assets/img/Appstore.png" alt="appstore">
            </a>
        </div>
        <div class="col-xl-6 row phone-container">
            <div class="phone1 col-6">
                <img src="./assets/img/phone1.png" alt="phone1">

            </div>
            <div class="phone2 col-6">
                <img src="./assets/img/phone2.png" alt="phone2">
            </div>
        </div>
    </div>
</section> -->
</div>
<!--
<footer></footer>
-->
