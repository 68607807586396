<h1 mat-dialog-title class="text-center border-bottom p-3">Contratar Plan</h1>

<label *ngIf="service.user.account_status == 'plan_unpaid'">Se tiene un cargo pendiente por el plan requerido</label>

<div mat-dialog-content class="m-0">
{{data.title}}
</div>

<div mat-dialog-actions align="end">
    <button mat-button mat-dialog-close>Cancelar</button>
    <button mat-button [mat-dialog-close]="true"> Aceptar </button>
</div>
