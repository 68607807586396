<nav class="navbar navbar-light bg-light">
    <a class="navbar-brand" [routerLink]="['/landing']" >
        <img src="./assets/logo.svg" width="30" height="30" alt="logo">
        <span> MindHelp </span>
    </a>
</nav>
<section class="container">
    <h3 class="txt-main mt-3"> Recuperar Contraseña </h3>

    <div class="container">
        <div class="row">
            <p class="col-12"> Ingrese su nueva contraseña </p>
            <mat-form-field class="col-6">
                <input matInput [(ngModel)]="newpass" type="password" placeholder="Nuevo password">
            </mat-form-field>
        </div>
        <button class="btn btn-primary" [disabled]="!(newpass.length > 4) " (click)="newPass()"> Enviar </button>
    </div>

</section>