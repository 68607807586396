import { Component, OnInit, OnDestroy, EventEmitter, Output } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';
import { AppState } from 'src/app/app.reducer';
import { Store } from '@ngrx/store';
import Swal from 'sweetalert2';
import { AuthService } from 'src/app/services/auth.service';
import { OpenpayService } from 'src/app/services/openpay.service';

declare var Conekta: any;
declare var OpenPay: any

var idUsusario = 0;

@Component({
  selector: 'app-add-card',
  templateUrl: './add-card.component.html',
  styleUrls: ['./add-card.component.scss']
})
export class AddCardComponent implements OnInit, OnDestroy {

  userSub: Subscription = new Subscription();
  userId = 0;

  tokenParams = {
    "card": {
      "card_number": "",
      "holder_name": "",
      "expiration_year": "",
      "expiration_month": "",
      "cvv2": "",
      "address": {
        "line1": "",
        "line2": "",
        "line3":"",
        "city": "",
        "state": "",
        "country_code": "",
        "postal_code":""
      }
    }
  }

  public cardFG: FormGroup;
  public publicKey = ''
  isLoading = false;

  constructor(
    public dialogRef: MatDialogRef<AddCardComponent>,
    private _formBuilder: FormBuilder,
    private store: Store<AppState>,
    public service: AuthService,
    public openpay: OpenpayService) { }

  ngOnInit() {
    this.publicKey = this.service.conektaApiKey;
    Conekta.setPublicKey(this.publicKey);
    Conekta.setLanguage("es");
    Conekta.api_key = 'key_4MPzwhkoj3wqmNktJ0C2N4e'

    this.userSub = this.store.select('user').subscribe(d => {
      this.userId = d.data.id;
      idUsusario = d.data.id
      console.log(this.userId);
    })

    this.cardFG = this._formBuilder.group({
      number: ['', [Validators.required, Validators.minLength(16), Validators.maxLength(16), Validators.pattern('^[0-9]*$')]],
      name: ['', [Validators.required, Validators.minLength(3), Validators.maxLength(100)]],
      exp_year: ['', [Validators.required, Validators.minLength(2), Validators.maxLength(2), Validators.pattern('^[0-9]*$')]],
      exp_month: ['', [Validators.required,Validators.minLength(2), Validators.maxLength(2), Validators.pattern('^[0-9]*$')]],
      cvc: ['', [Validators.required, Validators.minLength(3), Validators.maxLength(4), Validators.pattern('^[0-9]*$')]],
      street1: ['', [Validators.required, Validators.minLength(3), Validators.maxLength(100)]],
      street2: ['', [Validators.required, Validators.minLength(3), Validators.maxLength(100)]],
      city: ['', [Validators.required, Validators.minLength(3), Validators.maxLength(100)]],
      state: ['', [Validators.required, Validators.minLength(3), Validators.maxLength(100)]],
      zip: ['', [Validators.required, Validators.minLength(5), Validators.maxLength(5), Validators.pattern('^[0-9]*$')]],
      country: ['', [Validators.required, Validators.minLength(3), Validators.maxLength(100)]],
    });
  }

  ngOnDestroy() {
    this.userSub.unsubscribe();
  }

  async saveCard() {
    
    let card = {
      card: {
        card_number: this.cardFG.value.number,
        holder_name: this.cardFG.value.name,
        expiration_year: this.cardFG.value.exp_year,
        expiration_month: this.cardFG.value.exp_month,
        cvv2: this.cardFG.value.cvc,
        address: {
          line1: this.cardFG.value.street1,
          line2: this.cardFG.value.street2,
          line3: this.cardFG.value.city,
          city: this.cardFG.value.city,
          state: this.cardFG.value.state,
          country_code: 'MX',
          postal_code: this.cardFG.value.zip
        }
      }
    }
    /*
    let card = {
      card : {
        card_number:"4111111111111111",
        holder_name:"John Doe",
        expiration_year:"24",
        expiration_month:"12",
        cvv2:"110",
        address:{
          city:"Querétaro",
          line3:"Queretaro",
          postal_code:"76900",
          line1:"Av 5 de Febrero",
          line2:"Roble 207",
          state:"Queretaro",
          country_code:"MX"
        }
      }
    }
    */
    this.isLoading = true;
    console.log(card.card);
    OpenPay.setId('m1coum5skcmrq6pku2yv');
  ​  OpenPay.setApiKey('pk_da9513e220b247fb931f10910924b216');
    //OpenPay.setSandboxMode(true)
    //OpenPay.setSandboxMode(false)

    var deviceDataId = OpenPay.deviceData.setup();
    console.log('DEVICE SESSION ID:', deviceDataId)
    const token = await new Promise((resolve, reject) => {

      if (!OpenPay.card.validateCardNumber(card.card.card_number)) {

        Swal.fire({
          icon: 'error',
          title: 'Error',
          text: 'Número de tarjeta inválido. Por favor, verifica y vuelve a intentar.',
          confirmButtonColor: 'rgb(218, 0, 0)',
          confirmButtonText: 'OK',
        });
        this.isLoading = false;
        return; 
      }
      if (!OpenPay.card.validateCVC(card.card.cvv2)) {
        Swal.fire({
          icon: 'error',
          title: 'Error',
          text: 'CCV invalido. Por favor, verifica y vuelve a intentar.',
          confirmButtonColor: 'rgb(218, 0, 0)',
          confirmButtonText: 'OK',
        });
        this.isLoading = false;
        return; 
      }
      if (!OpenPay.card.validateExpiry(card.card.expiration_month, card.card.expiration_year)) {
        Swal.fire({
          icon: 'error',
          title: 'Error',
          text: 'Fecha de vigencia invalida. Por favor, verifica y vuelve a intentar.',
          confirmButtonColor: 'rgb(218, 0, 0)',
          confirmButtonText: 'OK',
        });
        this.isLoading = false;
        return; 
      }

      OpenPay.token.create(card.card, (carda, error) => {
        if (error) {
          console.log(error);
        } else {
          resolve(carda);
          console.log('TOKEN',carda)
          let body = {
            id_user: 1,
            device_session_id: deviceDataId,
            card_token: carda.data.id
          }

          this.service.addCard(body).subscribe((d:any) => {
            console.log(d)
            const decData = JSON.parse(this.service.decrypt(d.message, 'private'))
            console.log('DECDATA',decData)
            if(!d){
              this.isLoading = false;
              Swal.fire({
                icon: 'error',
                title: 'Error',
                text: decData.error.errors[0].description || decData.error.errors[0].raw,
                showConfirmButton: true,
                confirmButtonText: "ok",
                confirmButtonColor:'#023171',
              });
            }
            else{
              this.isLoading = false;
              Swal.fire({
                icon: 'success',
                title: 'Registro Correcto',
                text: "Tarjeta agregada con éxito",
                showConfirmButton: true,
                confirmButtonText: "ok",
                confirmButtonColor:'#023171',
              }).then((result) => {
                // La función de callback se ejecuta cuando el usuario hace clic en el botón de confirmación (ok)
                if (result.isConfirmed) {
                  this.dialogRef.close({id: decData.data.items[0].id})
                }
              });
              

            }
            //this.onNoClick();
          },err =>{
            this.isLoading = false;
            let decData = JSON.parse(this.service.decrypt(err.error.message,"private"));
            console.log(decData)
            Swal.fire({
              icon: 'error',
              title: 'Error',
              text: decData.error.errors[0].description || decData.error.errors[0].raw,
              showConfirmButton: true,
              confirmButtonText: "ok",
              confirmButtonColor:'#023171',
            });
          })
        }
      });
    });
  }

  async successResponseHandler(token) {
    // Do something on sucess
    // you need to send the token to the backend.
    let id = idUsusario;
    console.log(token.id, id)
    //test
    var url = this.service.newUrl + `users/${id}/payment/cards`
    // develop
    // var url = `https://api.mindhelp.dev/api/v1/users/${id}/payment/cards`
    //Prod
    //var url = `https://api.mindhelp.mx/api/v1/users/${id}/payment/cards`
    var data = { card_token: token.id };
    let encUser = { message: this.service.encrypt(data,"private") };

    await fetch(url, {
      method: 'POST', // or 'PUT'
      body: JSON.stringify(encUser), // data can be `string` or {object}!
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + this.service.token,
      }
    }).then(res => res.json())
      .catch(error => console.error('Error:', error))
      .then(response => {
        var decData = JSON.parse(this.service.decrypt(response.message,""));
        console.log(decData);
        if(decData.status == 500){
          Swal.fire({
            icon: 'error',
            title: 'Error',
            text: 'Se produjo un error al dar de alta la tarjeta',
            showConfirmButton: true,
            confirmButtonText: "ok",
            confirmButtonColor:'#023171',
          });
        }
        else{
          Swal.fire({
            icon: 'success',
            title: 'Registro Correcto',
            text: "Tarjeta agregada con éxito",
            showConfirmButton: true,
            confirmButtonText: "ok",
            confirmButtonColor:'#023171',
          });
        }
        this.onNoClick();
      });
  };


  errorResponseHandler(error) {
    // Do something on error
    console.log(error)
  };

  onNoClick(): void {
    this.dialogRef.close();
  }

}
