<h2 mat-dialog-title class="text-center border bg-main text-white p-3" > Seleccione una tarjeta </h2>
<button mat-button (click)="refresh()" class="dmsans" style="width: 100%;">
    <mat-icon> cached </mat-icon> Refrescar
</button>
<section class="container-fluid p-3">
    
    <!-- ng for tarejtas guardadas -->
    <div class="row justify-content-center">
        <div (click)="selectCard(i)" class="col-10 border border-secondary row align-items-center py-3 m-0 mb-3 cursor cardss" *ngFor="let c of savedCards; let i = index" [id]="'card'+i">
            <div class="col-2">
                <img  *ngIf="c.brand == 'amex'" src="./assets/img/amex.png"width="80px" alt="amex">
                <img *ngIf="c.brand == 'mastercard'" src="./assets/img/mastercard.jpg"width="80px" alt="mastercard">
                <img *ngIf="c.brand == 'visa'" src="./assets/img/visa.svg"width="80px" alt="visa">
            </div>
            <div class="col-8">
                {{c.card_number}} | exp: {{c.expiration_month}} / {{ c.expiration_year }}
            </div>
            <div class="col-2">
            <button mat-button color="primary" [style.visibility]="selectedCardIndex === i ? 'visible' : 'hidden'" (click)="pay(i)">Pagar</button>
            </div>
        </div>
        
    </div>
    <div class="serivcio">Servicio proporcionado por OpenPay</div>
    <div class="cards-container"></div>
    
  <div style="position: absolute; bottom: 10px; left: 10px; display: flex; flex-direction: column;">
    Se acepta:
    <div>
        <img src="../../../assets/img/mastercard.jpg" alt="OpenPay logo" style="height: 50px; width: auto; margin-left: 10px;">
        <img src="../../../assets/img/amex.png" alt="OpenPay logo" style="height: 50px; width: auto; margin-left: 10px;">
        <img src="../../../assets/img/visa.svg" alt="OpenPay logo" style="height: 50px; width: auto; margin-left: 10px;">
    </div>
  </div>
  <div style="position: absolute; bottom: 10px; right: 10px;">
    <img src="../../../assets/img/LogotipoOpenpay-01.jpg" alt="OpenPay logo" style="height: auto; width: 260px;">
  </div>
    
</section>

<section *ngIf="savedCards.length == 0" >
    <h4 class="text-secondary p-3" > No tienes Tarjetas registradas registra una <a class="text-primary" (click)="addCard()"> Aquí </a> </h4>
</section>

