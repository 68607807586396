<nav class="navbar navbar-light bg-light">
    <a class="navbar-brand" [routerLink]="['/landing']" >
        <img src="./assets/icono.png" width="30" height="30" alt="logo">
        <span> MindHelp </span>
    </a>
</nav>
<section class="container">
    <h3 class="txt-main mt-3"> Recuperar Contraseña </h3>

    <div class="container" *ngIf="!rePassSucc">
        <div class="row">
            <p class="col-12"> Ingrese su correo electronico para mandar la solicitud </p>
            <mat-form-field class="col-6">
                <input matInput [(ngModel)]="email" type="email" placeholder="email">
            </mat-form-field>
        </div>
        <button class="btn btn-primary" [disabled]="loading" (click)="rePass()">
            <span *ngIf="!loading"> Enviar </span>
            <span *ngIf="loading"> Cargando ... </span>
        </button>
    </div>

    <div class="container" *ngIf="rePassSucc">
      <h2 class="text-secondary"> Se enviara un correo para que pueda restablecer su contraseña </h2>
    </div>

</section>