<div class="center-flex img-edit">
    <input id="fileInpt" accept="image/*" (change)="getFile($event)" class="d-none" type="file">
    <button mat-fab class="edit-btn text-white" style="background-color: #023171 !important;" (click)="clickFile('fileInpt')">
        <mat-icon>edit</mat-icon>
    </button>
    <div class="img-container">
        <img width="100%" id="imgTag" src="../../../assets/img/icon_red.png" alt="profile-img">
    </div>
</div>
<h5 style="width: 100% ; text-align: center; margin-top: 20px;">Selecciona tu foto de perfil</h5>

<form [formGroup]="registerFG" (ngSubmit)="register()">
    <div class="row justify-content-center mt-5">
        <div class="col-xl-6">
            <mat-form-field class="full-width">
                <mat-label>Nombre(s)</mat-label>
                <input matInput formControlName="first_name" required placeholder="Nombre(s)">
                <div
                style="margin-right: 10px;"
                *ngIf="
                registerFG.controls['first_name'].hasError('minlength') ||
                registerFG.controls['first_name'].hasError('required') &&
                registerFG.controls['first_name'].touched"
                class="error-modal">
                El nombre debe tener mínimo 3 letras
                </div>
            </mat-form-field>
        </div>
        <div class="col-xl-6">
            <mat-form-field class="full-width">
                <mat-label>Apellidos</mat-label>
                <input matInput formControlName="last_name" required placeholder="Apellido">
                <div
                style="margin-right: 10px;"
                *ngIf="
                registerFG.controls['last_name'].hasError('minlength') ||
                registerFG.controls['last_name'].hasError('required') &&
                registerFG.controls['last_name'].touched"
                class="error-modal">
                El apellido debe tener mínimo 3 letras
                </div>
            </mat-form-field>
        </div>
        <div class="col-xl-6 col-12">
            <mat-form-field class="full-width">
                <mat-label>Correo electrónico</mat-label>
                <input matInput type="email" formControlName="email" placeholder="Email">
            </mat-form-field>
        </div>
        <div class="col-xl-6 col-12">
            <mat-form-field class="full-width">
                <mat-label>Teléfono</mat-label>
                <input matInput type="phone" formControlName="phone_number" required placeholder="Número celular" >
                <div
                style="margin-right: 10px;"
                *ngIf="
                registerFG.controls['phone_number'].hasError('minlength') ||
                registerFG.controls['phone_number'].hasError('required') &&
                registerFG.controls['phone_number'].touched"
                class="error-modal">
                El teléfono debe tener 10 dígitos".
                </div>
            </mat-form-field>
        </div>
        <div class="col-xl-6 col-12">
            <mat-form-field class="full-width">
                <mat-label>Pais</mat-label>
                <mat-select formControlName="country" required>
                    <mat-option value="MX">
                        México
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </div>
        <div class="col-xl-6 col-12">
            <mat-form-field class="full-width">
                <mat-label>Fecha de nacimiento</mat-label>
                <input matInput type="text" formControlName="date_of_birth"  placeholder="Fecha de Nacimiento">
            </mat-form-field>
        </div>
        <div class="col-xl-6 col-12">
            <mat-form-field class="full-width">
                <mat-label>Zona horaria</mat-label>
                <mat-select formControlName="time_zone" required [(ngModel)]="tmzselected">
                    <mat-option *ngFor="let tz of timeZones" [value]="tz">
                        {{tz}}
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </div>
        <div class="col-xl-6 col-12">
            <mat-form-field class="full-width">
                <mat-label>Género</mat-label>
                <mat-select formControlName="gender" required>
                    <mat-option value="Male">
                        Masculino
                    </mat-option>
                    <mat-option value="Female">
                        Femenino
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </div>

        <!-- Submit Button -->
        <div class="col-xl-7 col-12">
            <button  (click)="saveForm()" type="submit" class="full-width bg-main text-white btn">
                Guardar
            </button>
        </div>
    </div>
</form>