import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormGroup, Validators, FormBuilder, FormControl } from '@angular/forms';
import { Subscription } from 'rxjs';
import { Store } from '@ngrx/store';
import { AppState } from 'src/app/app.reducer';
import { AuthService } from 'src/app/services/auth.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-edit-profile',
  templateUrl: './edit-profile.component.html',
  styleUrls: ['./edit-profile.component.scss']
})
export class EditProfileComponent implements OnInit, OnDestroy {

  userSubscription: Subscription = new Subscription();

  public registerFG: FormGroup;
  hide = true;
  userImg: any;
  imgExt = "";
  userCv: any;
  espec = [];
  userId = 0;
  tmzselected = 'America/Mexico_City'
  countrySelected = 'MX'

  timeZones = [];

  constructor(private _formBuilder: FormBuilder, private store: Store<AppState>, private service: AuthService) { }

  ngOnInit() {
    this.service.getTimeZones().subscribe((d: String[]) => {
      this.timeZones = d;
      console.log(this.timeZones)
    })

    this.registerFG = this._formBuilder.group({
      first_name: ["", [Validators.required, Validators.minLength(3), Validators.maxLength(100)]],
      last_name: ["", [Validators.required, Validators.minLength(3), Validators.maxLength(100)]],
      email: [{ value: "", disabled: true },],
      country_code: ['+52', Validators.required],
      phone_number: ["", [Validators.required, Validators.minLength(10), Validators.maxLength(10)]],
      country: ["MX", Validators.required],
      date_of_birth: [{ value: "", disabled: true }],
      gender: ["", Validators.required],
      time_zone: ["America/Mexico_City", Validators.required],
    });

    this.userSubscription = this.store.select('user').subscribe(data => {
      let user = data.data;
      this.userId = user.id;

      this.service.getProfile(user.id).subscribe((d: any) => {
        let decUser = JSON.parse(this.service.decrypt(d.message,"private"));

        let profile = decUser.data.items[0];
        console.log(profile);

        let date = new Date(profile.birth_at);

        this.userImg = profile.photo_media_id ? profile.photo_media_id.url : "../../../assets/img/icon_red.png";
        document.getElementById("imgTag").setAttribute("src", this.userImg);

        this.registerFG.setValue({
          first_name: profile.first_name,
          last_name: profile.last_name,
          email: profile.email,
          country_code: '+52',
          phone_number: profile.phone,
          country: 'MX',
          date_of_birth: `${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()}`,
          gender: profile.gender,
          time_zone: 'America/Mexico_City',
        });

        console.log('FORMULARIO',this.registerFG.value)
      })
    })
  }

  ngOnDestroy() {
    this.userSubscription.unsubscribe();
  }

  register() {

    // let userData = new FormData();
    let date = this.registerFG.value.date_of_birth_convert;
    let day = date.getDate();
    let month = date.getMonth() + 1;
    let year = date.getFullYear();

    let user = {
      "role_id": (this.registerFG.value.role_id),
      "first_name": (this.registerFG.value.first_name),
      "last_name": (this.registerFG.value.last_name),
      "email": (this.registerFG.value.email),
      "password": (this.registerFG.value.password),
      "country_code": (this.registerFG.value.country_code),
      "phone_number": (this.registerFG.value.phone_number),
      "country": (this.registerFG.value.country),
      "date_of_birth": (`${day}-${month}-${year}`),
      "gender": (this.registerFG.value.gender),
      "time_zone": (this.registerFG.value.time_zone)
    }
    console.log(user)
  }

  clickFile(id) {
    document.getElementById(id).click();
  }

  getFile(event) {
    let file = event.target.files[0];
    this.imgExt = file.name.split(".")[1];
    let reader = new FileReader();
    reader.onload = this.fileOnload.bind(this);
    reader.readAsDataURL(file);
  }

  fileOnload(e) {
    let result = e.target.result;
    this.userImg = result.split(",")[1];
    document.getElementById("imgTag").setAttribute("src", result)
  }

  saveForm() {

    let date = this.registerFG.value.date_of_birth;
    console.log(this.registerFG.value.date_of_birth)

    let user = {
      "first_name": (this.registerFG.value.first_name),
      "last_name": (this.registerFG.value.last_name),
      "country_code": (this.registerFG.value.country_code),
      "phone": (this.registerFG.value.phone_number),
      "country": (this.registerFG.value.country),
      "gender": (this.registerFG.value.gender),
      "time_zone": "America/Mexico_City",
      "base64": this.userImg,
      base64_extension: this.imgExt
    }

    if(!this.registerFG.valid){
      Swal.fire({
        icon: 'error',
        title: 'Campos inválidos',
        text: "Alguno de los campos no es válido, favor de revisar.",
        showConfirmButton: true,
            confirmButtonText: "ok",
            confirmButtonColor:'#023171',
      });
      return
    }

    console.log(user)

    this.service.editProfile(user, this.userId).subscribe(data => {
      Swal.fire({
        icon: 'success',
        title: 'Listo',
        text: "Perfil modificado",
        showConfirmButton: true,
            confirmButtonText: "ok",
            confirmButtonColor:'#023171',
      });
    })
  }
}