<section class="video-container" >
  <div *ngIf="session" style="flex-direction: row; display: flex;">
    <img src="../../../assets/img/logo_gnp.png" style="position: absolute; top: 20px; right: 180px; height: 100px; width: auto; opacity: 0.4;"/>
    <app-publisher [session]="session" style="position: absolute; top: 0px; left: 0px; "></app-publisher>
    <app-subscriber class="doctor-camera" *ngFor="let stream of streams" [stream]="stream" [session]="session"
    [ngClass]="{'single-video': streams.length === 1, 'two-videos': streams.length === 2, 'three-videos': streams.length === 3, 'four-videos': streams.length === 4}"
    ></app-subscriber>
    <h1 *ngIf="streams.length == 0" class="text-white">  </h1>
  </div>
</section>

<section class="buttons-container">
  <button class="call-button ml-3" (click)="videoSettings('video')" > 
    <mat-icon class="text-white" *ngIf="!this.video.video">videocam_off</mat-icon> 
    <mat-icon class="text-white" *ngIf="this.video.video" >videocam</mat-icon> 
  </button>
  <button class="call-button ml-3" (click)="videoSettings('mic')" > 
    <mat-icon class="text-white" *ngIf="!this.video.audio" >mic_off</mat-icon> 
    <mat-icon class="text-white" *ngIf="this.video.audio" >mic</mat-icon> 
  </button>
  <button class="call-button ml-3 bg-danger" (click)="endCall()" > <mat-icon class="text-white" >phone_disabled</mat-icon> </button>
</section> 
