<section class="container-fluid">
    <div class="back-btn py-2">
        <a class="center-flex" [routerLink]="['/panel/home']">
            <mat-icon> arrow_back_ios </mat-icon> Regresar
        </a>
    </div>
    <div class="row content-info">
        <div class="col-xl-6 col-12 row">
            <div class="row align-items-center col-12 mt-3 mx-0">
                <div class="col-auto">
                    <div class="avatar-container">
                        <img [src]="doctor.photo_media_id ? doctor.photo_media_id.url : './assets/img/profile.jpg'" 
                        (error)="setDefaultPic($event)" width="100%" alt="avatar">
                    </div>
                </div>
                <div class="col-8">
                    <!--<p class="m-0"> {{doctor.services[0].name}} </p>-->
                    <p> <b> {{doctor.first_name}} {{doctor.last_name}} </b> </p>
                </div>
                <!--<div class="col-auto center-flex">
                    <mat-icon class="text-warning">star</mat-icon>
                    <mat-icon class="text-secondary ml-2">start</mat-icon>
                    <span class="text-secondary ml-2"> {{averageReviews}} </span>
                </div>-->
                <!-- datos contacto -->
                <div class="col-10 offset-2 row p-0 ayudamedios">
                    <!-- phone -->
                    
                    <div class="col-12 row align-items-center p-0">
                        <div class="icon-info col-auto" style="padding: 0 !important; background-color: #7D2716 !important; align-items: center; justify-content: center;">
                            <mat-icon class="text-white" style="margin: 2px 0 0 2px !important;"> phone </mat-icon>
                        </div>
                        <strong class="col-8 shrink-on-phone"> {{doctor.phone}} </strong>
                    </div>
                    <!-- email -->
                    <div class="col-12 row align-items-center p-0">
                        <span class="icon-info col-auto" style="padding: 0 !important; background-color: #7D2716 !important;">
                            <mat-icon class="text-white" style="margin: 2px 0 0 2px !important;"> alternate_email </mat-icon>
                        </span>
                        <strong class="col-10 shrink-on-phone"> {{doctor.email}} </strong>
                        <!--
                        <a class="icon-doctor col-auto" *ngIf="doctor.resume_media_id"
                            [href]="doctor.resume_media_id.url" target="_blank" [matTooltip]="'Ver CV'" style="padding: 0;">
                            <mat-icon class="text-white">edit_document</mat-icon>
                        </a>
                        -->
                    </div>

                    <!--<div class="col-12 row align-items-center p-0">
                        <span class="icon-info col-auto" style="padding: 0; background-color: #00c0de;">
                            
                        </span>
                        <strong class="col-8"> En {{onlyMonth}} {{totalDates}} citas disponibles</strong>
                    </div>-->
                </div>

                <div class="col-12 row mt-3">
                    <!--<div class="col">
                        <p class="txt-secondary text-center"> {{reviewsList.length}} Reseñas </p>
                    </div>-->
                    <div class="col">
                        <p class="txt-secondary text-center"> {{formatDate(doctor.working_since || 0)}} de experiencia </p>
                    </div>
                    <!--
                    <div class="col">
                        <p class="txt-secondary text-center"> {{ doctor.birth_at | date:'mediumDate' }} </p>
                    </div>
                    -->
                </div>
                <div class="col-12">
                    <p class="text-dark"> {{doctor.user_description}} </p>
                </div>
                <div class="col-auto py-2">
                    <a class="txt-main cursor" *ngIf="doctor.resume_media_id" (click)="openPdfModal(doctor.resume_media_id.url)" target="_blank"> Ver currículum </a>
                </div>
                <mat-divider class="col-12 "></mat-divider>
            </div>

            <!-- Reviews -->
            <!--
            <div class="col-12">
                <h3 class="col-12 text-center mt-3"> Reseñas </h3>
                <div class="row m-0 border p-3" *ngFor="let review of reviewsList" >
                    <div class="col-12" >-->
                        <!-- <div class="avatar-container">
                            <img [src]="r.profile_image_url" width="100%" alt="avatar">
                        </div> -->
                    <!--</div>
                    <div class="review-info">
                        <p>-->
                            <!-- <b> {{r.first_name}} {{r.last_name}} </b> <span class="text-secondary small ml-3">
                                {{ r.created_at | date:'shortDate' }} </span> -->
                            <!--<br>
                            <span class="review-star">
                                <mat-icon class="text-warning">star</mat-icon>
                                <mat-icon class="text-secondary ml-2">start</mat-icon>
                                <span class="text-secondary ml-2"> {{review.score}} </span>
                            </span>
                        </p>
                        <p style="margin-bottom: 0;"> {{review.message}} </p>
                    </div>
                </div>
            </div>
            -->
        </div>

        <div class="col-xl-6 col-12 row align-items-center">
            <div class="col-12 p-4 border shadow ">
                <h2> Agendar sesión </h2>
                <mat-divider></mat-divider>
                <!-- Dates select -->
                <p class="text-secondary text-muted mt-3"> Fecha y hora de la sesión </p>
                <div class="info-block shadow-sm row m-0">
                    <!-- Calendario -->
                    <div class="calendar-wrapper" style="display: flex; flex-wrap: wrap; padding-top: 15px;">
                        <div class="horario-container">
                            <mat-icon style="margin-top: 19px; color: #023171;">calendar_today</mat-icon>
                            <mat-form-field color="accent" style="margin-right: 2vw; margin-left: 0.5vw;" class="horario-selector">
                                <mat-label style=" white-space: normal">Selecciona una fecha</mat-label>
                                <input matInput [matDatepicker]="picker2" (dateChange)="handleDateChange($event)" [min]="minDateLimit">
                                <mat-datepicker-toggle matIconSuffix [for]="picker2"></mat-datepicker-toggle>
                                <mat-datepicker #picker2 color="primary" ></mat-datepicker>
                            </mat-form-field>       
                        </div>
                        <div class="horario-container">
                            <mat-icon style="margin-top: 19px; margin-right: 0.5vw; color: #023171;">access_time</mat-icon>
                            <mat-form-field  class="horario-selector">
                                <mat-label>Horarios disponibles</mat-label>
                                <mat-select>
                                    <mat-option *ngFor="let t of doctorTimeSlots" [value]="t.start" (click)="getTime(t.start)">
                                        {{t.start_hour}}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>

                    </div>
                    
                </div>
                <!-- horario -->
                <!--
                <div class="row px-4 my-3 align-items-center" *ngIf="isDatePicker">
                    <div class="col-5">
                        <div class="info-date p-3">
                            <p class="m-0"> <strong>{{ dateString }}</strong> </p>
                            <p class="small text-muted m-0"> {{ doctorTimeSlots.length }} citas disponibles </p>
                        </div>
                    </div>
                    <div class="col-7">
                        <mat-button-toggle-group class="row border-none fullw" #group="matButtonToggleGroup" (change)="getTime(group.value)"  >
                            <ng-container *ngFor="let t of doctorTimeSlots" >
                                <mat-button-toggle class="col-6" *ngIf="t.slot_type == 'availability'" [value]="t.start">
                                    {{t.start_hour}} </mat-button-toggle>
                            </ng-container>
                        </mat-button-toggle-group>
                    </div>
                </div>
                -->
                <!--<div class="info-block shadow-sm row m-0">
                    <div class="col-12" (click)="picker.open()">
                        <button mat-button class="txt-main">
                            <mat-icon>calendar_today_outline</mat-icon>
                        </button>
                        <mat-form-field>
                            <input matInput [matDatepicker]="picker" (dateChange)="getDate($event.value)" disabled
                                [min]="minDate" placeholder="Seleciona una fecha">
                            <mat-datepicker disabled=false #picker></mat-datepicker>
                        </mat-form-field>
                    </div>
                    <div class="col-12">
                        <button mat-button class="txt-main">
                            <mat-icon>access_time</mat-icon>
                        </button>
                        <mat-form-field>
                            <mat-label>{{timeLabel}}</mat-label>
                            <mat-select (selectionChange)="getFee()" [(ngModel)]="doctorTime">
                                <ng-container *ngFor="let t of doctorTimeSlots">
                                    <mat-option  *ngIf="t.slot_type == 'availability'" [value]="t.start">
                                        {{t.start_hour}}
                                    </mat-option>
                                </ng-container>
                            </mat-select>
                        </mat-form-field>
                    </div>
                </div>-->
                <!-- La consulta incluye -->
                <h5 class="text-secondary mt-3"> Esta sesión incluye </h5>
                <div class="info-block shadow-sm row m-0 p-2" style=" align-items: center;">
                    <p class="m-0 col-1">
                        <mat-icon class="txt-main">people_alt</mat-icon>
                    </p>
                    <p class="m-0 text-secondari col-11 text-smol" style="white-space: wrap;">Una videollamada de 1 hora</p>
                    <!--<p class="m-0 col-1">
                        <mat-icon class="txt-main">chat_bubble_outline</mat-icon>
                    </p>
                    <p class="m-0 text-secondari col"> Mensajes de textos ilimitados por una semana </p>-->
                </div>
                <!-- Aplicar cupon -->
                <ng-container *ngIf="(doctorFee != 0 && !creditValid)">
                    <h5 class="text-secondary mt-3"> Aplicar cupón </h5>
                    <div class="info-block shadow-sm row m-0 p-2">
                        <div>
                            <mat-form-field class="col-10">
                                <input matInput [(ngModel)]="cupon" placeholder="Ingresa el cupón">
                            </mat-form-field>
                            <button id="appBtn" (click)="applyCupon()" mat-button class="col-12 col-sm-2 col-md-2 bg-main text-white mt-4" style="height: 57px; margin-bottom: 26px;"> Aplicar
                            </button>
                        </div>
                        <span *ngIf="cupon != ''">Dar clic en "Aplicar" para validar el cupón.</span>
                        
                    </div>
                    <p class="txt-main text-right" *ngIf="cuponValid"> {{cuponText}} </p>
                    <p class="text-danger text-right" *ngIf="!cuponValid"> {{cuponText}} </p>
                </ng-container>
                <!-- Datos extras -->
                <!--<h5 class="text-secondary mt-3">-->
                    <!--<button class="btn btn-link" (click)="showExtras = !showExtras">Datos del paciente</button>
                    <small>(Solo cuando los datos registrados no son del paciente)</small>-->
                <!--</h5>-->
                <div class="info-block shadow-sm row m-0 p-2" *ngIf="showExtras">
                    <!--<p class="m-0 col-1">
                        <mat-icon class="txt-main">label</mat-icon>
                    </p>-->
                    <mat-form-field class="col-6">
                        <input matInput [(ngModel)]="extra_nombre" placeholder="Nombre" maxlength="50">
                    </mat-form-field>
                    <!--<p class="m-0 col-1">
                        <mat-icon class="txt-main">label</mat-icon>
                    </p>-->
                    <mat-form-field class="col-6">
                        <input matInput [(ngModel)]="extra_apellido" placeholder="Apellido" maxlength="50">
                    </mat-form-field>
                </div>
                <!--<ng-container *ngIf="(doctorFee != 0 && service.user.company_id && !cuponValid)">
                  <h5 class="text-secondary mt-3">Créditos</h5>
                  <div class="info-block shadow-sm row m-0 p-2">
                      <div class="col-10">
                        <span class="m-2">
                          <mat-icon class="txt-main" style="transform: scale(2);">account_balance_wallet</mat-icon>
                        </span>
                        <span class="m-2 text-secondari"> Disponibles: </span>
                        <span class="font-weight-bold" *ngIf="!service.user.max_uses"> Ilimitado </span>
                        <span class="font-weight-bold" *ngIf="service.user.max_uses"> {{service.user.uses || '0'}} </span>
                      </div>
                      <div class="col-2">
                        <button mat-button (click)="disapplyCredit()" class="bg-danger text-white" *ngIf="creditValid">Desaplicar</button>
                        <button mat-button (click)="applyCredit()" class="bg-main text-white" *ngIf="!creditValid">Utilizar</button>
                      </div>
                      <small>Créditos otorgados por tu empresa</small>
                  </div>
                </ng-container>-->
                <!-- Precio y descuentos -->
                <div class="price" *ngIf="(doctorFee != 0 )">
                    <div class="price row justify-cont m-0 mt-3">
                        <p class="text-dark font-weight-bold col-10"> Total de la consulta </p>
                        <p class="font-weight-bold col-2"> {{getMoney(doctorFee) }} </p>
                    </div>
                    <div class="price row justify-cont m-0 mt-3" *ngIf="cuponValid">
                        <p class="text-dark font-weight-bold col-10 txt-main "> Promo </p>
                        <p class="font-weight-bold col-2 txt-main m-0"> {{getMoney(promo) }} </p>
                    </div>
                    <mat-divider></mat-divider>
                    <div class="price row justify-cont m-0">
                        <p class="text-dark font-weight-bold col-10"> Total</p>
                        <p class="font-weight-bold col-2"> {{getMoney(totalFee) }} </p>
                    </div>
                    <div class="price row justify-cont m-0 mt-3" *ngIf="cuponValid">
                        <p class="font-weight-bold col-10 txt-main"> Estás ahorrando </p>
                        <p class="font-weight-bold col-2 txt-main"> {{getMoney(descuento) }} </p>
                    </div>
                </div>

                <button mat-button class="col-12 bg-main text-white mt-4" (click)="saveBooking()"
                    [disabled]="!dateSelected"> Agendar videollamada </button>
            </div>
        </div>
    </div>
</section>
