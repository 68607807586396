<mat-accordion>
    <!-- FAQ -->
    <mat-expansion-panel>

        <mat-expansion-panel-header>
            <mat-panel-title align="center">
                ¿Qué es Mind Help?
            </mat-panel-title>
        </mat-expansion-panel-header>

        <p class="text">
            Mind Help brinda servicios de terapia psicológica con los mejores profesionales para todo tipo de personas.
            <br><br>
            Nuestra misión es ayudar a las personas a vivir una vida plena y exitosa conectándolos con los mejores
            profesionales de la salud mental de habla hispana, a todas horas y desde cualquier lugar. <br> <br>
            Con Mind Help puedes tener sesiones en vivo desde tu celular o tableta, intercambiar mensajes de texto y
            documentos con tu terapeuta de manera privada, confidencial y a un precio accesible sin tener que gastar en
            transporte, tiempo y horarios inconvenientes.

        </p>

    </mat-expansion-panel>
    <!-- FAQ -->
    <mat-expansion-panel>

        <mat-expansion-panel-header>
            <mat-panel-title align="center">
                ¿Los terapeutas de Mind Help son profesionales?
            </mat-panel-title>
        </mat-expansion-panel-header>

        <p class="text">
            Todos y cada uno de nuestros psicólogos cuentan con los estudios universitarios y posgrados. Cada uno de los
            miembros de nuestra red ha tenido que comprobar un mínimo de 2,000 horas de experiencia clínica y tener
            referencias profesionales impecables. Nuestro proceso de reclutamiento y selección es sumamente estricto.
            <br><br>
            Mind Help cuenta además con un equipo de consultores expertos en las áreas de salud mental para garantizar
            que nuestro servicio se lleva a cabo con los niveles de calidad requeridos y prometidos a nuestros usuarios.
        </p>

    </mat-expansion-panel>
    <!-- FAQ -->
    <mat-expansion-panel>

        <mat-expansion-panel-header>
            <mat-panel-title align="center">
                ¿Qué tan efectiva es realmente la terapia en línea?
            </mat-panel-title>
        </mat-expansion-panel-header>

        <p class="text">
            ¡Es muy efectiva! Existe una gran cantidad de artículos e investigación que demuestran la efectividad de la
            terapia en línea, te compartimos algunas referencias, casi todas en inglés aunque ya están surgiendo nuevas
            investigaciones en español.
            <br><br>
            Barak, Azy, et al. (2008). “A comprehensive review and a meta-analysis of the effectiveness of
            internet-based psychotherapeutic interventions.” Journal of Technology in Human Services, 26.2-4: 109-160.
            <br>
            <a href="http://www.tandfonline.com/doi/abs/10.1080/15228830802094429#.VI8vaCvF_hs" target="_blank">
                http://www.tandfonline.com/doi/abs/10.1080/15228830802094429#.VI8vaCvF_hs
            </a>
            <br><br>
            “Internet-based versus face-to-face cognitive-behavioral intervention for depression: A randomized
            controlled non-inferiority trial” <br>
            <a href="http://www.jad-journal.com/article/S0165-0327(13)00512-0/pdf" target="_blank">
                http://www.jad-journal.com/article/S0165-0327(13)00512-0/pdf
            </a>
            <br><br>
            Andersson, G., & Cuijpers, P. (2009). “Internet-based and other computerized psychological treatments for
            adult depression: A meta-analysis.” Cognitive Behaviour Therapy, 38(4), 196-205. <br>
            <a href="http://www.ncbi.nlm.nih.gov/pubmed/20183695" target="_blank">
                http://www.ncbi.nlm.nih.gov/pubmed/20183695
            </a>
            Wantland, D. J., Portillo, C. J., Holzemer, W. L., Slaughter, R., & McGhee, E. M. (2004). “The effectiveness
            of Web-based vs. non-Web-based interventions: a meta-analysis of behavioral change outcomes.” Journal of
            Medical Internet Research, 6(4). <br>
            <a href="http://www.jmir.org/2004/4/e40/" target="_blank">
                http://www.jmir.org/2004/4/e40/
            </a>
            <br><br>
            “Internet-versus group-administered cognitive behaviour therapy for panic disorder in a psychiatric setting:
            a randomised trial” <br>
            <a href="http://www.biomedcentral.com/1471-244X/10/54" target="_blank">
                http://www.biomedcentral.com/1471-244X/10/54
            </a>
            <br><br>
            “Home telemental health implementation and outcomes using electronic messaging.” <br>
            <a href="http://www.ncbi.nlm.nih.gov/pubmed/22052966" target="_blank">
                http://www.ncbi.nlm.nih.gov/pubmed/22052966
            </a>
            <br><br>
            “Mobile Assessment and Treatment for Schizophrenia (MATS): a pilot trial of an interactive text-messaging
            intervention for medication adherence, socialization, and auditory hallucinations.” <br>
            <a href="http://www.ncbi.nlm.nih.gov/pubmed/22080492" target="_blank">
                http://www.ncbi.nlm.nih.gov/pubmed/22080492
            </a>
            <br><br>
            Linda Godleski, M.D.; Adam Darkins, M.D., M.P.H.; John Peters, M.S. (2014) “Outcomes of 98,609
            U.S. Department of Veterans Affairs Patients Enrolled in Telemental Health Services,
            2006–2010” <br>
            <a href="http://dx.doi.org/10.1176/appi.ps.201100206" target="_blank">
                http://dx.doi.org/10.1176/appi.ps.201100206
            </a>
            <br><br>
            Antiretroviral Medication Adherence for HIV/Aids: <br>
            <a href="http://www.ncbi.nlm.nih.gov/pmc/articles/PMC3718389/" target="_blank">
                http://www.ncbi.nlm.nih.gov/pmc/articles/PMC3718389/
            </a> <br>
            <a href="http://www.biomedcentral.com/content/pdf/1745-6215-12-5.pdf" target="_blank">
                http://www.biomedcentral.com/content/pdf/1745-6215-12-5.pdf
            </a>
            <br><br>
            “Distance Therapy Comes of Age.” Scientific American. <br>
            <a href="http://www.scientificamerican.com/article/distance-therapy-comes-of-age/" target="_blank">
                http://www.scientificamerican.com/article/distance-therapy-comes-of-age/
            </a>
        </p>

    </mat-expansion-panel>
    <!-- FAQ -->
    <mat-expansion-panel>

        <mat-expansion-panel-header>
            <mat-panel-title align="center">
                ¿Cómo sé que mi información está protegida y confidencial con Mind Help?
            </mat-panel-title>
        </mat-expansion-panel-header>

        <p class="text">
            En Mind Help la seguridad de tu información y tu privacidad es nuestra más alta prioridad. Nuestra
            tecnología contiene los más altos estándares de seguridad.
            <br><br>
            Toda tu información está encriptada en servidores y todo tipo de comunicación dentro de nuestra aplicación
            también. Además nuestra app requiere que ingreses un password que agrega otro nivel de seguridad.

        </p>

    </mat-expansion-panel>
    <!-- FAQ -->
    <mat-expansion-panel>

        <mat-expansion-panel-header>
            <mat-panel-title align="center">
                ¿Cuánto cuesta usar Mind Help?
            </mat-panel-title>
        </mat-expansion-panel-header>

        <p class="text">
            Con Mind Help puedes pagar por cada sesión que tengas con tu psicólogo o puedes usar el código que tu
            empresa te dé cómo parte de una prestación laboral.
            <br><br>
            1 Sesión de Terapia: $700 Pesos Mexicanos, incluye: 1 video sesión en vivo con su terapeuta de 1 hora de
            duración. Tu terapeuta responde tus mensajes de texto diariamente 5 días de la semana.
            <br><br>
            *Precios más IVA en caso de requerir factura
            <br><br>
            Consulte con su empresa aseguradora para saber si cubre los servicios de salud mental brindados por Mind
            Help.
        </p>

    </mat-expansion-panel>
    <!-- FAQ -->
    <mat-expansion-panel>

        <mat-expansion-panel-header>
            <mat-panel-title align="center">
                ¿Cuál es la política de reembolso?
            </mat-panel-title>
        </mat-expansion-panel-header>

        <p class="text">
            En Mind Help nos interesa ofrecer el mejor servicio posible y que nuestros usuarios hagan uso de nuestra
            plataforma de manera recurrente.
            <br><br> Si por alguna razón el servicio de su psicólogo no le agradó puede cambiar de psicólogo en
            cualquier momento. Cada uno de nuestros terapeutas tiene un acuerdo firmado con Mind Help en el cual se
            comprometen a brindar el mejor servicio posible y también a recibir una compensación por el mismo.
            <br><br>Si usted agendó una sesión y por alguna razón usted no se presentó, el terapeuta debe recibir su
            compensación ya que es tiempo que reservó exclusivamente para usted, por lo tanto no podemos reembolsar el
            dinero pagado por esta sesión.
            <br><br>Nuestro departamento de atención al cliente revisa caso por caso porque sabemos que puede haber
            elementos fuera del su control. Usualmente lo que hemos logrado hacer es negociar con nuestro terapeuta para
            reagendar la sesión en el futuro.

        </p>

    </mat-expansion-panel>
    <!-- FAQ -->
    <mat-expansion-panel>

        <mat-expansion-panel-header>
            <mat-panel-title align="center">
                Menores de edad
            </mat-panel-title>
        </mat-expansion-panel-header>

        <div>
            <h6>
                Soy el tutor / Padre de un adolescente usando Mind Help, ¿Puedo hablar con su terapeuta para acceder a
                la información sobre sus avances en el tratamiento?
            </h6>
            <p class="text">
                Si, pero requieres tener el permiso por escrito de tu hijo para compartir su información contigo. Además
                debe compartir con su terapeuta tu nombre, dirección, teléfono y el consentimiento de su terapeuta.
                <br><br>
                Lo mejor es que hables con tu hijo y le preguntes si desea compartir lo que está hablando en su terapia
                y si te permite hablar con su terapeuta directamente. Si tú, tu hijo y el terapeuta están cómodos con
                esta situación no debe haber necesidad de los documentos por escrito.
            </p>
            <h6>
                Si mi hijo menor de edad está interesado en recibir terapia en Mind Help, ¿cómo le otorgo
                consentimiento?
            </h6>
            <p class="text">
                Ya que es un menor de edad requiere un permiso de sus padres o tutor para comenzar su terapia. Con un
                mensaje escrito, correo electrónico a su terapeuta y a Mind Help otorgando consentimiento es suficiente.
            </p>
            <h6>
                ¿Los servicios de Mind Help los cubre el seguro de gastos médicos?
            </h6>
            <p class="text">
                Verifique en su póliza de seguro o con su compañía de seguros si tienen cobertura para servicios de
                terapia psicológica en línea.
            </p>
        </div>

    </mat-expansion-panel>
    <!-- FAQ -->
    <mat-expansion-panel>

        <mat-expansion-panel-header>
            <mat-panel-title align="center">
                Sobre nuestros psicólogos
            </mat-panel-title>
        </mat-expansion-panel-header>

        <div>
            <h6>
                ¿Cómo puedo unirme al equipo de psicólogos de Mind Help?
            </h6>
            <p class="text">
                Entra a <a href="www.mindhelp.mx" target="_blank"> www.mindhelp.mx </a> ingresa a la sección
                “TERAPEUTA”, enviamos tus datos y CV desde la forma de
                contacto manifestando tu interés por trabajar como socio terapeuta en Mind Help.
                Si eres candidato viable, una persona de Mind Help te contactará para comenzar tu proceso.

            </p>
        </div>

    </mat-expansion-panel>
    <!-- FAQ -->
    <mat-expansion-panel>

        <mat-expansion-panel-header>
            <mat-panel-title align="center">
                Sobre mi privacidad
            </mat-panel-title>
        </mat-expansion-panel-header>

        <div>
            <h6>
                ¿Cómo protege Mind Help mi privacidad?
            </h6>
            <p class="text">
                Este es un tema prioritario para nosotros en Mind Help. Toda tu información está altamente protegida y
                permanece confidencial. Todos tus datos son encriptados en servidores y todo tipo de comunicación entre
                nuestra plataforma y los servidores también es encriptada. Además para hacer uso de Mind Help necesitas
                crear una cuenta personalizada y un password.
                <br><br>
                Nuestro sistema de pago cuenta con los más altos estándares de la industria. Toda la información
                personal se mantiene en resguardo por un sistema procesador de pagos nacional. Nunca guardamos ningún
                tipo de información financiera personal.
            </p>
            <h6>
                ¿Puedo borrar todo lo que haya escrito dentro de la aplicación?
            </h6>
            <p class="text">
                Puedes dar de baja tu cuenta en el momento que lo desees. Toda la información contenida dentro de tu
                cuenta incluido lo que hayas escrito por chat u otro medio dentro de la app será eliminado
                automáticamente. Toda la información que desees borrar dentro de la app puedes hacerlo en el momento que
                así lo consideres.
            </p>
        </div>

    </mat-expansion-panel>
    <!-- FAQ -->
    <mat-expansion-panel>

        <mat-expansion-panel-header>
            <mat-panel-title align="center">
                Respuesta en mensaje de texto por parte del terapeuta
            </mat-panel-title>
        </mat-expansion-panel-header>

        <div>
            <h6>
                ¿Cómo funciona el sistema de mensajes de texto durante la semana con mi psicólogo?
            </h6>
            <p class="text">
                Los psicólogos en Mind Help están obligados a cumplir con un tiempo de respuesta estipulado a los
                mensajes de texto enviados que les haga llegar su paciente.
                <br><br>
                Cuando necesites saber algo de tu terapeuta puedes usar el sistema de mensajería de texto dentro de la
                app y te responderá por el mismo medio con un mensaje de texto, voz o video.

            </p>
            <h6>
                ¿Cuáles son los tiempos de respuesta de mi psicólogo si lo contacto por mensajes de texto?
            </h6>
            <p class="text">
                Todos los terapeutas de Mind Help tienen la obligación de responder al menos una vez al día durante 5
                días hábiles de la semana a los mensajes de texto de sus pacientes, funciona de la siguiente manera:
                <br><br>
                Si preguntas entre 7:00 hrs – 11:00 hrs entonces tu psicólogo responde antes de las 15:00 hrs
                <br><br>
                Si preguntas entre 11:00 hrs - 16:00 hrs entonces tu psicólogo responde antes de las 19:00 hrs
                <br><br>
                Si preguntas entre 16:00 hrs y 7:00 hrs entonces tu psicólogo responde antes de las 10:00 hrs del
                siguiente día laboral
            </p>
            <h6>
                ¿Qué pasa si mi psicólogo no contesta mi mensaje en el tiempo garantizado de respuesta?
            </h6>
            <p class="text">
                Esto en realidad no debería pasar casi nunca, ya que el compromiso de nuestros psicólogos con sus
                pacientes es total. Si por alguna razón llega a suceder, tu derecho a recibir el mensaje permanece y no
                se elimina, tu terapeuta contestará tu solicitud un poco más tarde.
            </p>
            <h6>
                ¿Qué tipo de respuesta debo esperar de mi psicólogo en los mensajes de texto?
            </h6>
            <p class="text">
                El compromiso de tu psicólogo es contestar dentro del tiempo y forma establecido. Se espera que al menos
                recibas un mensaje profesional y de acuerdo a las expectativas profesionales del terapeuta. Recuerda que
                siempre tienes la opción de retroalimentar dentro de la app el servicio y profesionalismo que te brinda
                tu terapeuta en la sección de comentarios y también puedes calificar con estrellas el nivel de servicio
                que recibiste de tu psicólogo. </p>
        </div>

    </mat-expansion-panel>
    <!-- FAQ -->
    <mat-expansion-panel>

        <mat-expansion-panel-header>
            <mat-panel-title align="center">
                Pago, facturación, reembolso
            </mat-panel-title>
        </mat-expansion-panel-header>

        <div>
            <h6>
                ¿Cuál es la política de reembolso?
            </h6>
            <p class="text">
                En Mind Help nos interesa ofrecer el mejor servicio posible y que nuestros usuarios hagan uso de nuestra
                plataforma de manera recurrente.
                <br><br>
                Si por alguna razón el servicio de su psicólogo no le agradó puede cambiar de psicólogo en cualquier
                momento. Cada uno de nuestros terapeutas tiene un acuerdo firmado con Mind Help en el cual se
                comprometen a brindar el mejor servicio posible y también a recibir una compensación por el mismo.
                <br><br>
                Si usted agendó una sesión y por alguna razón usted no se presentó, el terapeuta debe recibir su
                compensación ya que es tiempo que reservó exclusivamente para usted, por lo tanto no podemos reembolsar
                el dinero pagado por esta sesión.
                <br><br>
                Nuestro departamento de atención al cliente revisa caso por caso porque sabemos que puede haber
                elementos fuera del su control. Usualmente lo que hemos logrado hacer es negociar con nuestro terapeuta
                para reagendar la sesión en el futuro.
            </p>
            <h6>
                ¿Cuáles son los tipos de pago que acepta Mind Help?
            </h6>
            <p class="text">
                Aceptamos la mayoría de las tarjetas de crédito y débito, transferencias bancarias y depósitos en
                efectivo.
                <br><br>
                Puedes ver las opciones disponibles de pago dentro de la app al momento de pagar una sesión.

            </p>
            <h6>
                ¿Puedo recibir una factura por mi pago?
            </h6>
            <p class="text">
                Sí, puedes compartirnos tus datos fiscales por correo y te enviaremos tu factura electrónica.

            </p>
            <h6>
                ¿Puedo comprar terapia para un ser querido?
            </h6>
            <p class="text">
                Por supuesto que si, para quien tu quieras, todos somos humanos y todos necesitamos apoyo profesional en
                algún momento de nuestras vidas.
            </p>
        </div>

    </mat-expansion-panel>
    <!-- FAQ -->
    <mat-expansion-panel>

        <mat-expansion-panel-header>
            <mat-panel-title align="center">
                Preguntas técnicas
            </mat-panel-title>
        </mat-expansion-panel-header>

        <div>
            <h6>
                ¿Cómo cancelo mi cuenta?
            </h6>
            <p class="text">
                En la sección de ajustes / contáctanos envías un correo solicitando tu baja de la app. <br><br>
                ¡Esperemos que este momento no tenga que llegar y seas usuario de Mind Help cada vez que lo necesites!

            </p>
            <h6>
                ¿Si por alguna razón no hago uso de mis sesiones que ya tengo pagadas, se pueden ir acumulando?
            </h6>
            <p class="text">
                Si por causas de fuerza mayor necesitas reagendar tu sesión, puedes ponerte de acuerdo con tu
                terapeuta. La idea es hacer uso de tu sesión de acuerdo a los tiempos que sugiera tu terapeuta.
            </p>
            <h6>
                ¿Puedo tener una sesión usando mi computadora?
            </h6>
            <p class="text">
                Al día de hoy todos nuestros servicios deben llevarse a cabo solamente a través de tu celular Android.
                En un futuro cercano estará disponible el servicio a través de nuestra página web.
            </p>
            <h6>
                ¿Qué tipo de teléfono se requiere para usar Mind Help?
            </h6>
            <p class="text">
                Puedes usar teléfonos con sistemas Android solamente, lo puedes encontrar en la tienda de aplicaciones
                de Google Play.
                En un futuro cercano tendremos lista nuestra app para sistemas iOS de Apple.
            </p>
            <h6>
                ¿Qué tipo de conexión a internet necesito?
            </h6>
            <p class="text">
                Nuestra sugerencia es que es extremadamente importante que al momento de tener tu sesión estés conectado
                a una red wifi que te garantice la mejor experiencia en video y audio posible. Asegúrate de estar en un
                lugar cómodo, privado y sin interrupciones donde puedas concentrarte en tu sesión.
                <br><br>
                Procura usar audífonos con micrófono integrado, tener más del 80% de batería, ya que una sesión de una
                hora puede consumir gran parte de la batería de tu celular. Si te es posible mantén conectado a la
                electricidad tu celular durante tu sesión.
                <br><br>
                Si por alguna razón no te es posible estar conectado a una red wifi, recuerda que puedes agendar una
                sesión o incluso tener tu video sesión en tu celular con tu plan de datos normal, asegúrate de tener
                suficientes datos para tener una video llamada de aproximadamente una hora.
            </p>
        </div>

    </mat-expansion-panel>
</mat-accordion>