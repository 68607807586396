import { Component, OnInit, Inject, ViewChildren, ViewEncapsulation } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import * as moment from 'moment';
import { MatCalendar } from '@angular/material/datepicker';

@Component({
  selector: 'app-filters',
  templateUrl: './filters.component.html',
  styleUrls: ['./filters.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class FiltersComponent implements OnInit {

  @ViewChildren('calendar') calendar: MatCalendar<any>;

  minDate;
  maxDate;
  public daysSelected: any[] = [];
  event: any;
  // filtros
  nombre = "";
  genero = null;
  horaInicio = 9;
  horaInicioIsPm = false;
  horaFin = 7;
  horaFinIsPm = true;

  calendario;

  // variable calendario
  fechaCalendario = "";

  horas = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];

  constructor(
    public dialogRef: MatDialogRef<FiltersComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) { }

  isSelected = (event: any) => {
    const date =
      event.getFullYear() +
      "-" +
      ("00" + (event.getMonth() + 1)).slice(-2) +
      "-" +
      ("00" + event.getDate()).slice(-2);
    return this.daysSelected.find(x => x == date) ? "selected" : null;
  };

  select(event: any, calendar: any) {
    const date =
      event.getFullYear() +
      "-" +
      ("00" + (event.getMonth() + 1)).slice(-2) +
      "-" +
      ("00" + event.getDate()).slice(-2);
    const index = this.daysSelected.findIndex(x => x == date);
    if (index < 0) this.daysSelected.push(date);
    else this.daysSelected.splice(index, 1);
    console.log(calendar)
    this.calendario = calendar;
    calendar.updateTodaysDate();
  }

  ngOnInit(): void {
    this.minDate = moment().format();
    this.maxDate = moment().format();
    console.log(this.data)
  }

  onNoClick(): void {
    this.dialogRef.close(false);
  }

  buscar() {
    
    let datesSort = this.daysSelected.sort();

    let data = {
      nombre: this.nombre,
      genero: this.genero,
      horaInicio: this.horaInicioIsPm ? Number(this.horaInicio)+12 : this.horaInicio,
      horaInicioIsPm: this.horaInicioIsPm,
      horaFin: this.horaFinIsPm ? Number(this.horaFin)+12 : this.horaFin,
      horaFinIsPm: this.horaFinIsPm,
      fechaInicio: this.daysSelected.length > 0 ? datesSort[0]:'',
      fechaFin: this.daysSelected.length > 0 ? datesSort[this.daysSelected.length-1]:'',
    }
    console.log(data,datesSort)
    this.dialogRef.close(data);

  }

  clearFilters(){
    this.nombre = "";
    this.genero = null;
    this.horaInicio = 1;
    this.horaInicioIsPm = false;
    this.horaFin = 7;
    this.horaFinIsPm = true;
    this.daysSelected = [];
    if(this.calendario){
      this.calendario.updateTodaysDate();
    } 
  }
}
