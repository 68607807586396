<div class="center-flex">
    <button mat-button (click)="refresh()" class="dmsans">
        <mat-icon> cached </mat-icon> Refrescar
    </button>
</div>
<section class="container-fluid">
    <mat-tab-group (selectedTabChange)="changeTab($event)">
        <!-- Today -->
        <mat-tab label="Hoy">
            <div class="container-fluid">
                <ng-container *ngFor="let b of bookings">
                    <div *ngIf="b.status != 'declined'" class="row content-info mb-4">
                        <div class="col-xl-7 col-12 row">
                            <div class="row col-12 mt-3 mx-0">
                                <div class="col-auto">
                                    <img *ngIf="b.doctor_user_id"
                                        [src]="b.doctor.photo_media_id ? b.doctor.photo_media_id.url : './assets/img/profile.jpg'"
                                        class="rounded-circle avatar" (error)="setDefaultPic($event)" alt="avatar">
                                </div>
                                <div class="col-7">
                                    <h3> <b> {{b.doctor_user_id.first_name}} {{b.doctor_user_id.last_name}} </b> </h3>
                                    <p class="m-0 txt-main"> {{b.category_name}} </p>
                                    <p class="m-0 mr-2 txt-main">
                                        <mat-icon>phone</mat-icon> {{b.doctor_user_id.phone}}
                                    </p>
                                </div>
                                <div class="col-auto">
                                    Estatus:
                                    <span *ngIf="b.status == 'scheduled_appointment'" class="badge dmsans" style="background-color: #084464 !important;"
                                        [ngClass]="{'badge-danger': b.status == 'expired' || b.status == 'declined', 'badge-success': b.status != 'expired' }">
                                        {{returnStatus(b.status)}} </span>
                                </div>
                            </div>
                            <div class="col-12 row align-items-center">
                                <div class="col-12 p-4">
                                    <mat-divider></mat-divider>
                                    <div class="row">
                                        <!-- Dates select -->
                                        <div class="col-xl-6 col-12">
                                            <h5 class="text-secondary mt-3"> Fecha y hora de la sesión </h5>
                                            <div class="info-block shadow-sm row m-0 p-2">
                                                <div class="col-6 center-flex">
                                                    <p class="m-0 mr-2 txt-main">
                                                        <mat-icon>calendar_today_outline</mat-icon>
                                                    </p>
                                                    <span class="text-secondary"> {{b.start_at | date:'mediumDate' }}
                                                    </span>
                                                </div>
                                                <div class="col-6 center-flex">
                                                    <p class="m-0 mr-2 txt-main">
                                                        <mat-icon>access_time</mat-icon>
                                                    </p>
                                                    <span class="text-secondary"> {{b.start_at | date:'shortTime'}}
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                        <!-- La consulta incluye -->
                                        <div class="col-xl-6 col-12">
                                            <h5 class="text-secondary mt-3"> Esta sesión incluye </h5>
                                            <div class="info-block shadow-sm row m-0 p-2">
                                                <p class="m-0 col-1">
                                                    <mat-icon class="txt-main">people_alt</mat-icon>
                                                </p>
                                                <p class="m-0 text-secondari col-11">Una videollamada de 1 hora</p>
                                                <!--<p class="m-0 col-1">
                                                    <mat-icon class="txt-main">chat_bubble_outline</mat-icon>
                                                </p>
                                                <p class="m-0 text-secondari col"> Chat ilimitado por una semana </p>-->
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row" *ngIf="b.extra_firstname">
                                        <div class="col-xl-12 col-12">
                                            <h5 class="text-secondary mt-3"> Datos extras </h5>
                                            <div class="info-block shadow-sm row m-0">
                                                <div class="col-6 center-flex">
                                                    <p class="m-0 mr-2 txt-main">Nombre del paciente</p>
                                                    <span class="text-secondary"> {{b.extra_firstname || ''}}</span>
                                                </div>
                                                <div class="col-6 center-flex">
                                                    <p class="m-0 mr-2" style="color: #084464 !important;">Apellido del paciente</p>
                                                    <span class="text-secondary"> {{b.extra_lastname || ''}}</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!-- Cuadro de accion -->
                        <div class="col-xl-5 col-12 row align-items-center">
                            <div class="col-12 p-4 border shadow container-fluid">
                                <div class="row justify-content-between">
                                    <h2> Tomar sesión </h2>
                                    <!--<h2 class="txt-main"> {{b.price}} </h2>-->
                                </div>
                                <mat-divider></mat-divider>
                                <!-- La consulta incluye -->
                                <!--<h5 class="text-secondary mt-3"> Da clic en el botón para INICIAR tu video sesión </h5>-->
                                <!--<div class="info-block shadow-sm row m-0 p-2">
                                    <p class="m-0 text-secondari col-11">Cancelacion gratuita 24h antes </p>
                                    <p class="m-0 text-secondari col">Cancelar el {{b.start_at | date:'mediumDate' }} no
                                        hay devoluciones </p>
                                </div>-->
                                <div class="container-fluid mt-3">
                                    <div class="row justify-content-between">
                                        <button (click)="goSession(b)" type="button" style="background-color: #084464 !important;"
                                            [disabled]="cheackDates(b.start_at)" class="btn text-white col-14">
                                            <mat-icon style="padding-top: 8px;">videocam_outline</mat-icon> Video
                                        </button>
                                        <!--
                                        <button (click)="goChat(b)" type="button" style="background-color: #084464 !important;" [disabled]="cheackDates(b.start_at)"
                                            class="btn text-white col-5">
                                            <mat-icon>chat_bubble_outline</mat-icon> Chat
                                        </button>
                                        -->
                                    </div>
                                </div>
                            </div>
                        </div>
                        <mat-divider class="col-12"></mat-divider>
                    </div>
                </ng-container>
            </div>
        </mat-tab>
        <!-- Next -->
        <mat-tab label="Próximas">
            <div class="container-fluid">
                <div *ngFor="let b of bookingsUpcoming" class="row content-info mb-4">
                    <div class="col-xl-7 col-12 row">
                        <div class="row col-12 mt-3 mx-0">
                            <div class="col-auto">
                                <img *ngIf="b.doctor_user_id"
                                        [src]="b.doctor.photo_media_id ? b.doctor.photo_media_id.url : './assets/img/profile.jpg'"
                                        class="rounded-circle avatar" (error)="setDefaultPic($event)" alt="avatar">
                            </div>
                            <div class="col-8">
                                <h3> <b> {{b.doctor_user_id.first_name}} {{b.doctor_user_id.last_name}} </b> </h3>
                                <p class="m-0" style="color: #084464 !important;"> {{b.category_name}} </p>
                            </div>
                            <div class="col-auto" style="background-color: #084464 !important; height: 30px !important;">
                                <span
                                    [ngClass]="{'badge-danger': b.status == 'expired' || b.status == 'declined'  , 'badge-success': b.status != 'expired' }"
                                    class="badge" style="background-color: #084464 !important;">{{returnStatus(b.status)}}</span>
                            </div>
                        </div>
                        <div class="col-12 row align-items-center">
                            <div class="col-12 p-4">
                                <mat-divider></mat-divider>
                                <div class="row">
                                    <!-- Dates select -->
                                    <div class="col-xl-6 col-12">
                                        <h5 class="text-secondary mt-3" style="color: #084464 !important;"> Fecha y hora de la sesión </h5>
                                        <div class="info-block shadow-sm row m-0 p-2">
                                            <div class="col-6 center-flex">
                                                <p class="m-0 mr-2" style="color: #084464 !important;">
                                                    <mat-icon style="color: #084464 !important;">calendar_today_outline</mat-icon>
                                                </p>
                                                <span class="text-secondary"> {{b.start_at | date:'mediumDate' }}
                                                </span>
                                            </div>
                                            <div class="col-6 center-flex">
                                                <p class="m-0 mr-2">
                                                    <mat-icon style="color: #084464 !important;">access_time</mat-icon>
                                                </p>
                                                <span class="text-secondary"> {{b.start_at | date:'shortTime'}} </span>
                                            </div>
                                        </div>
                                    </div>
                                    <!-- La consulta incluye -->
                                    <div class="col-xl-6 col-12">
                                        <h5 class="text-secondary mt-3"> Esta sesión incluye </h5>
                                        <div class="info-block shadow-sm row m-0 p-2">
                                            <p class="m-0 col-1">
                                                <mat-icon style="color: #084464 !important;">people_alt</mat-icon>
                                            </p>
                                            <p class="m-0 text-secondari col-11">Una videollamada de 1 hora </p>
                                            <!--<p class="m-0 col-1">
                                                    <mat-icon class="txt-main">chat_bubble_outline</mat-icon>
                                                </p>
                                                <p class="m-0 text-secondari col"> Chat ilimitado por una semana </p>-->
                                        </div>
                                    </div>
                                </div>
                                <div class="row" *ngIf="b.extra_firstname">
                                    <div class="col-xl-12 col-12">
                                        <h5 class="text-secondary mt-3"> Datos extras </h5>
                                        <div class="info-block shadow-sm row m-0">
                                            <div class="col-6 center-flex">
                                                <p class="m-0 mr-2" style="color: #084464 !important;">Nombre del paciente</p>
                                                <span class="text-secondary"> {{b.extra_firstname || ''}}</span>
                                            </div>
                                            <div class="col-6 center-flex">
                                                <p class="m-0 mr-2" style="color: #084464 !important;">Apellido del paciente</p>
                                                <span class="text-secondary"> {{b.extra_lastname || ''}}</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- Cuadro de accion -->
                    <div class="col-xl-5 col-12 row align-items-center" *ngIf="b.status != 'to_be_confirmed_by_therapist'">
                        <div class="col-12 p-4 border shadow container-fluid">
                            <div class="row justify-content-between">
                                <h2> Reagendar ó Cancelar</h2>
                                <!--<h2 class="txt-main"> {{b.price}} </h2>-->
                            </div>
                            <mat-divider></mat-divider>
                            <!-- La consulta incluye -->
                            <!--<h5 class="text-secondary mt-3"> Condiciones </h5>
                            <div class="info-block shadow-sm row m-0 p-2">
                                <p class="m-0 text-secondari col-11">24h antes de la sesión </p>
                                <p class="m-0 text-secondari col">No
                                    hay devoluciones </p>Se puede reagendar hasta el {{(b.start_at - 86400000) | date:'mediumDate' }},
                            </div>-->
                            <div class="container-fluid mt-3">
                                <div class="row justify-content-between">
                                    <button [disabled]="checkReDate(b.start_at)" (click)="reDateBooking(b)" type="button" class="btn text-white col-5" style="background-color: #084464 !important;">
                                        <mat-icon style="padding-top: 4px;">insert_invitation</mat-icon> Reagendar
                                    </button>
                                    <button [disabled]="checkReDate(b.start_at)" (click)="showCancel(b)" type="button" class="btn text-white col-5" style="background-color: #084464 !important;">
                                        <mat-icon style="padding-top: 4px;">close</mat-icon> Cancelar
                                    </button>
                                </div>
                                <br>
                                Cualquier duda llamar al teléfono de contacto de médica móvil o al whatsapp de contacto terapeuta.
                            </div>
                        </div>
                    </div>

                    <div class="col-xl-5 col-12 row align-items-center" *ngIf="b.status == 'to_be_confirmed_by_therapist'">
                        <div class="col-12 p-4 border shadow container-fluid">
                            <div class="row justify-content-between">
                                <h2>Cancelar solicitud</h2>
                                <!--<h2 class="txt-main"> {{b.price}} </h2>-->
                            </div>
                            <mat-divider></mat-divider>
                            <!-- La consulta incluye -->
                            <!--<h5 class="text-secondary mt-3"> Condiciones </h5>
                            <div class="info-block shadow-sm row m-0 p-2">
                                <p class="m-0 text-secondari col-11">24h antes de la sesión </p>
                                <p class="m-0 text-secondari col">No
                                    hay devoluciones </p>Se puede reagendar hasta el {{(b.start_at - 86400000) | date:'mediumDate' }},
                            </div>-->
                            <div class="container-fluid mt-3">
                                <div class="row justify-content-between">
                                    <button [disabled]="checkReDate(b.start_at)" (click)="showCancel(b, 2)" type="button" class="btn text-white col-5" style="background-color: #084464 !important;">
                                        <mat-icon style="padding-top: 4px;">close</mat-icon> Cancelar
                                    </button>
                                </div>
                                <br>
                                Cualquier duda llamar al teléfono de contacto de médica móvil o al whatsapp de contacto terapeuta.
                            </div>
                        </div>
                    </div>
                    <mat-divider class="col-12"></mat-divider>
                </div>

                <div *ngFor="let b of bookingsToBeCancelled" class="row content-info mb-4">
                    <div class="col-xl-7 col-12 row">
                        <div class="row col-12 mt-3 mx-0">
                            <div class="col-auto">
                                <img *ngIf="b.doctor_user_id"
                                        [src]="b.doctor.photo_media_id ? b.doctor.photo_media_id.url : './assets/img/profile.jpg'"
                                        class="rounded-circle avatar" (error)="setDefaultPic($event)" alt="avatar">
                            </div>
                            <div class="col-8">
                                <h3> <b> {{b.doctor_user_id.first_name}} {{b.doctor_user_id.last_name}} </b> </h3>
                                <p class="m-0" style="color: #084464 !important;"> {{b.category_name}} </p>
                            </div>
                            <div class="col-auto" style="background-color: #084464 !important; height: 30px !important;">
                                <span
                                    [ngClass]="{'badge-danger': b.status == 'expired' || b.status == 'declined'  , 'badge-success': b.status != 'expired' }"
                                    class="badge" style="background-color: #084464 !important;">{{returnStatus(b.status)}}</span>
                            </div>
                        </div>
                        <div class="col-12 row align-items-center">
                            <div class="col-12 p-4">
                                <mat-divider></mat-divider>
                                <div class="row">
                                    <!-- Dates select -->
                                    <div class="col-xl-6 col-12">
                                        <h5 class="text-secondary mt-3" style="color: #084464 !important;"> Fecha y hora de la sesión </h5>
                                        <div class="info-block shadow-sm row m-0 p-2">
                                            <div class="col-6 center-flex">
                                                <p class="m-0 mr-2" style="color: #084464 !important;">
                                                    <mat-icon style="color: #084464 !important;">calendar_today_outline</mat-icon>
                                                </p>
                                                <span class="text-secondary"> {{b.start_at | date:'mediumDate' }}
                                                </span>
                                            </div>
                                            <div class="col-6 center-flex">
                                                <p class="m-0 mr-2">
                                                    <mat-icon style="color: #084464 !important;">access_time</mat-icon>
                                                </p>
                                                <span class="text-secondary"> {{b.start_at | date:'shortTime'}} </span>
                                            </div>
                                        </div>
                                    </div>
                                    <!-- La consulta incluye -->
                                    <div class="col-xl-6 col-12">
                                        <h5 class="text-secondary mt-3"> Esta consulta incluye </h5>
                                        <div class="info-block shadow-sm row m-0 p-2">
                                            <p class="m-0 col-1">
                                                <mat-icon style="color: #084464 !important;">people_alt</mat-icon>
                                            </p>
                                            <p class="m-0 text-secondari col-11">Una sesion de una hora </p>
                                            <!--<p class="m-0 col-1">
                                                    <mat-icon class="txt-main">chat_bubble_outline</mat-icon>
                                                </p>
                                                <p class="m-0 text-secondari col"> Chat ilimitado por una semana </p>-->
                                        </div>
                                    </div>
                                </div>
                                <div class="row" *ngIf="b.extra_firstname">
                                    <div class="col-xl-12 col-12">
                                        <h5 class="text-secondary mt-3"> Datos extras </h5>
                                        <div class="info-block shadow-sm row m-0">
                                            <div class="col-6 center-flex">
                                                <p class="m-0 mr-2" style="color: #084464 !important;">Nombre del paciente</p>
                                                <span class="text-secondary"> {{b.extra_firstname || ''}}</span>
                                            </div>
                                            <div class="col-6 center-flex">
                                                <p class="m-0 mr-2" style="color: #084464 !important;">Apellido del paciente</p>
                                                <span class="text-secondary"> {{b.extra_lastname || ''}}</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- Cuadro de accion -->
                    <!--
                    <div class="col-xl-5 col-12 row align-items-center">
                        <div class="col-12 p-4 border shadow container-fluid">
                            <div class="row justify-content-between">
                                <h2> Reagendar ó Cancelar</h2>
                            </div>
                            <mat-divider></mat-divider>
                            <div class="container-fluid mt-3">
                                <div class="row justify-content-between">
                                    <button [disabled]="checkReDate(b.start_at)" (click)="reDateBooking(b)" type="button" class="btn text-white col-5" style="background-color: #084464 !important;">
                                        <mat-icon>insert_invitation</mat-icon> Reagendar
                                    </button>
                                    <button [disabled]="checkReDate(b.start_at)" (click)="showCancel(b)" type="button" class="btn text-white col-5" style="background-color: #084464 !important;">
                                        <mat-icon>close</mat-icon> Cancelar
                                    </button>
                                </div>
                                <br>
                                Cualquier duda llamar al teléfono de contacto de médica móvil o al whatsapp de contacto terapeuta.
                            </div>
                        </div>
                    </div>-->
                    <mat-divider class="col-12"></mat-divider>
                </div>
            </div>
        </mat-tab>
        <!-- Past -->
        <mat-tab label="Finalizadas">
            <div class="container-fluid">
                <div class="row">
                    <div *ngFor="let b of bookingsPast" class="row col-xl-6 col-12 content-info mb-4">
                        <div class="col-12 row">
                            <div class="row col-12 mt-3 mx-0">
                                <div class="col-auto">
                                    <img *ngIf="b.doctor_user_id"
                                        [src]="b.doctor.photo_media_id ? b.doctor.photo_media_id.url : './assets/img/profile.jpg'"
                                        class="rounded-circle avatar" (error)="setDefaultPic($event)" alt="avatar">
                                </div>
                                <div class="col-8">
                                    <h3> <b> {{b.doctor_user_id.first_name}} {{b.doctor_user_id.last_name}} </b> </h3>
                                    <p class="m-0" style="color: #084464 !important;"> {{b.category_name}} </p>
                                </div>
                                <div class="col-auto">
                                    <span class="badge" style="background-color: #084464 !important;"
                                        [ngClass]="{'badge-danger': b.status == 'expired' || b.status == 'declined', 'badge-success': b.status != 'expired' }">{{returnStatus(b.status)}}</span>
                                </div>
                            </div>
                            <div class="col-12 row align-items-center">
                                <div class="col-12 p-4">
                                    <mat-divider></mat-divider>
                                    <div class="row">
                                        <!-- Dates select -->
                                        <div class="col-12">
                                            <h5 class="text-secondary mt-3"> Fecha y hora de la sesión </h5>
                                            <div class="info-block shadow-sm row m-0 p-2">
                                                <div class="col-6 center-flex">
                                                    <p class="m-0 mr-2 txt-main">
                                                        <mat-icon>calendar_today_outline</mat-icon>
                                                    </p>
                                                    <span class="text-secondary"> {{b.start_at | date:'mediumDate' }}
                                                    </span>
                                                </div>
                                                <div class="col-6 center-flex">
                                                    <p class="m-0 mr-2 txt-main">
                                                        <mat-icon>access_time</mat-icon>
                                                    </p>
                                                    <span class="text-secondary"> {{b.start_at | date:'shortTime'}}
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                        <button mat-raised-button class="col-12 mt-2 text-white" style="background-color: #084464;"
                                            [routerLink]="['/panel/booking-now',b.doc_id,b.cat_id]"> Volver a Agendar
                                        </button>
                                    </div>
                                    <div class="row" *ngIf="b.extra_firstname">
                                        <div class="col-xl-12 col-12">
                                            <h5 class="text-secondary mt-3"> Datos extras </h5>
                                            <div class="info-block shadow-sm row m-0">
                                                <div class="col-6 center-flex">
                                                    <p class="m-0 mr-2 txt-main">Nombre del paciente</p>
                                                    <span class="text-secondary"> {{b.extra_firstname || ''}}</span>
                                                </div>
                                                <div class="col-6 center-flex">
                                                    <p class="m-0 mr-2 txt-main">Apellido del paciente</p>
                                                    <span class="text-secondary"> {{b.extra_lastname || ''}}</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <mat-divider class="col-12"></mat-divider>
                    </div>
                </div>
                <div class="row mb-2" *ngIf="showGetMore">
                    <div class="col-12">
                        <button type="button" style="background-color: #084464 !important;" mat-button class="full-width text-white get-more" (click)="getBookingsPast()">
                            <span *ngIf="!isLoading"> Ver más </span>
                            <span *ngIf="isLoading" > Cargando ... </span>
                        </button>
                    </div>
                </div>
            </div>
        </mat-tab>
    </mat-tab-group>
</section>
