<section class="container">
    <div class="row mt-4 s-box">
        <div class="col-xl-3 col-12 p-2 row border info-container">
            <div class="">
                <img *ngIf="user.photo_media_id" width="200px"
                    [src]="userImg"
                    alt="avatar">
            </div>
            <h5 class="name-text mt-2 text-center"> {{user.first_name}} {{user.last_name}} </h5>
            <div class="col-12">
                <div class="tab p-2 cursor" routerLinkActive="active-link">
                    <a (click)="changeTitle('Editar perfil')" class="tab-text text-center txt-main m-0" [routerLink]="['/panel/settings/edit-profile']"> Editar perfil </a>
                </div>
                
                <div class="tab p-2 cursor" routerLinkActive="active-link">
                    <a (click)="changeTitle('Métodos de pago')" class="tab-text text-center txt-main m-0" [routerLink]="['/panel/settings/tarjetas']"> Métodos de pago </a>
                </div>
                <!--
                <div class="tab p-2 cursor" routerLinkActive="active-link">
                    <a (click)="changeTitle('Planes')" class="tab-text text-center txt-main m-0" [routerLink]="['/panel/settings/subscripciones']"> Planes </a>
                </div>-->
                <!--
                <div class="tab p-2 cursor" routerLinkActive="active-link">
                    <a (click)="changeTitle('Cambiar contraseña')" class="tab-text text-center txt-main m-0" [routerLink]="['/panel/settings/change-password']"> Cambiar contraseña </a>
                </div>
                -->
                <!--<div class="tab p-2 cursor" routerLinkActive="active-link">
                    <a (click)="changeTitle('Contactanos')" class="tab-text text-center txt-main m-0" [routerLink]="['/panel/settings/contact']"> Contactanos </a>
                </div>-->
                <!--
                <div class="tab p-2 cursor" routerLinkActive="active-link">
                    <a (click)="changeTitle('Preguntas frecuentes')" class="tab-text text-center txt-main m-0" [routerLink]="['/panel/settings/faq']"> Preguntas frecuentes </a>
                </div>
                -->
                <div class="tab p-2 cursor" routerLinkActive="active-link">
                    <a (click)="changeTitle('Términos y condiciones')" class="tab-text text-center txt-main m-0" (click)="openTerms()"> Términos y condiciones </a>
                </div>
                
            </div>

        </div>
        <div class="col-xl-9 col-12 border p-0" style="background-color: white;">
            <div class="title border-bottom p-4">
                <h3 class="text-center"> {{title}} </h3>
            </div>
            <section class="panel-content p-3">
                <router-outlet></router-outlet>
            </section>

        </div>
    </div>
</section>
