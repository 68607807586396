import { Injectable } from '@angular/core';
import * as openpay from 'openpay';

@Injectable({
  providedIn: 'root'
})
export class OpenpayService {
  private openpayInstance: openpay;
  constructor() { 
    this.initOpenpay();
  }

  private initOpenpay() {
    // Initialize Openpay here
  }

  makePayment(amount: number, card: any) {
    return new Promise((resolve, reject) => {
      this.openpayInstance.cards.create(card, (response: any) => {
        if (response.error_code) {
          reject(response);
        } else {
          console.log(response)
          // Use the token to make a payment or perform other actions
          // For example: Call your backend to complete the payment
          resolve(response);
        }
      });
    });
  }
}
