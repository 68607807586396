<div class="container p-5">
    <div class="row">
        <div class="col-md-12">
            <h1 class="text-center">Validación de Zona Horaria</h1>
            <p>
                Hemos notado que algunos de nuestros pacientes han tenido algunas inconsistencias 
                en los horarios dentro de la plataforma y en Mindhelp nos preocupamos por la 
                tranquilidad de nuestros pacientes. Estas inconsistencias son debido al retiro del 
                cambio de horario en México.
            </p>
            <p>
                Por tal motivo, solicitamos tu ayuda para validar que la hora y la zona horaria 
                configurada en tu equipo si coincida con el que se debe tener registrada y asi 
                no se tenga ninguna dificultad para agendar.
            </p>
            <p>
                Lamentamos mucho las molestias ocasionadas.
            </p>
            <p>Nuestro sistema detecta la siguiente información en tu perfil:</p>
        </div>
    </div>
    <div class="row">
        <div class="col-md-6 bold">Zona horaria:</div>
        <div class="col-md-6"><b>(UTC{{data?.data.utc_offset}})</b> {{data?.data.timezone || ''}}</div>
    </div>
    <div class="row">
        <div class="col-md-6 bold">Fecha y Hora Actual:</div>
        <div class="col-md-6">{{data?.data.datetime | date:'medium' || ''}}</div>
    </div>
    <div class="row m-5">
        <div class="col-md-4">
            <button class="btn btn-primary btn-lg bg-danger" (click)="showHideInfoCorrecta()">Mi información no es correcta</button>
        </div>
        <div class="col-md-4">
            <button class="btn btn-secondary btn-lg" (click)="showHideVeriInformacion()">No se como verificar esta información</button>
        </div>
        <div class="col-md-4">
            <button class="btn btn-primary btn-lg bg-main" (click)="looksGood()">Todo luce bien con mi información</button>
        </div>
    </div>
    <div class="row" *ngIf="infoIncorrecta">
        <div class="col-md-12 text-center">
            <p>Para corregir esta información es necesario ir a tu perfil y modificar la zona horaria</p>
            <a (click)="irAPerfil()">Ir a mi Perfil</a>
        </div>
    </div>
    <div class="row" *ngIf="veriInformacion">
        <div class="col-md-12">
            <p>Enseguida te dejamos enlaces de videos donde se indica como validar la información en tu equipo y modificarla de ser necesario</p>
        </div>
        <div class="col-md-4 text-center">
            <h3>Windows 10</h3>
            <a (click)="verVideo('10')">Ver video</a>
        </div>
        <div class="col-md-4 text-center">
            <h3>Windows 11</h3>
            <a (click)="verVideo('11')">Ver video</a>
        </div>
        <div class="col-md-4 text-center">
            <h3>MAC OS</h3>
            <a (click)="verVideo('MAC')">Ver video</a>
        </div>
    </div>
    <div class="row m-5">
        <div class="col-md-12">
            <h4 class="text-center">Agradecemos tu tiempo.</h4>
        </div>
    </div>
</div>