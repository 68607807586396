<div class="container">
    <div class="title row p-2 align-items-center">
        <div class="col-2">
            <button mat-icon-button (click)="onNoClick()">
                <mat-icon>close</mat-icon>
            </button>
        </div>
        <h4 class="txt-main font-weight-bold m-0 col-8 text-center">Búsqueda de terapeutas</h4>
    </div>
    <mat-divider></mat-divider>
    <!-- nombre -->
    <div class="row px-4">
        <mat-form-field class="col-12">
            <input matInput [(ngModel)]="nombre" placeholder="Nombre">
        </mat-form-field>
    </div>
    <!-- genero -->
    <div class="row px-4">
        <p class="txt-main2 col-12"> Género </p>
        <mat-radio-group class="col-12" aria-label="Genero" [(ngModel)]="genero" color="primary">
            <mat-radio-button value="female">Femenino</mat-radio-button>
            <mat-radio-button value="male" class="ml-5">Masculino</mat-radio-button>
        </mat-radio-group>
    </div>
    <div class="row px-4 mt-2;" style=" margin-top: 20px !important">
        <p class="txt-main2 col-12"><strong> Disponibilidad </strong></p>
    </div>
    <div class="row px-4 mb-3" style="display: flex; flex-direction: row; align-items: center;">
        <!-- Fecha -->
        <div style="max-width: 370px;">
            <p>Fecha</p>
            <mat-calendar class="calendar" #calendar [minDate]="minDate" (selectedChange)="select($event,calendar)"
                [dateClass]="isSelected" style="width: 100% !important;"></mat-calendar>
        </div>
        <!-- Hora -->
        <div class="border-left pl-4" style="max-width: 370px;">
            <p class="col-12 p-0">Hora</p>
            <!-- inicio -->
            <div class="form-group row">
                <label for="inputInicio" class="col-sm-2 col-form-label">Inicio</label>
                <div class="col-sm-6">
                    <select class="form-control" [(ngModel)]="horaInicio" id="inputInicio">
                        <option *ngFor="let h of horas"> {{h}} </option>
                    </select>
                </div>
                <div class="col-sm-4">
                    <span>AM</span>
                    <mat-slide-toggle [(ngModel)]="horaInicioIsPm" style="margin-left: 5px; margin-right: 5px;">PM</mat-slide-toggle>
                </div>
            </div>
            <!-- fin -->
            <div class="form-group row">
                <label for="inputInicio" class="col-sm-2 col-form-label">Final</label>
                <div class="col-sm-6">
                    <select class="form-control" [(ngModel)]="horaFin" id="inputInicio">
                        <option *ngFor="let h of horas"> {{h}} </option>
                    </select>
                </div>
                <div class="col-sm-4">
                    <span>AM</span>
                    <mat-slide-toggle [(ngModel)]="horaFinIsPm"  style="margin-left: 5px; margin-right: 5px;">PM</mat-slide-toggle>
                </div>
            </div>
        </div>
    </div>
    <mat-divider></mat-divider>
    <div class="row px-4 mt-4 justify-content-between">
        <input class="custom-btn btn-lg " type="button" value="ELIMINAR FILTROS" (click)="clearFilters()" >
        <input class="btn btn-primary btn-lg bg-main" type="button" (click)="buscar()" value="BUSCAR">

    </div>
</div>