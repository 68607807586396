
<form [formGroup]="registerFG" (ngSubmit)="saveForm()">
    <div class="row justify-content-center mt-5">
        <div class="col-xl-6">
            <mat-form-field class="full-width">
                <input matInput type="password" formControlName="password" required placeholder="Contraseña antigüa">
            </mat-form-field>
            <mat-form-field class="full-width">
                <input matInput type="password" formControlName="repassword" required placeholder="Nueva contraseña">
            </mat-form-field>
        </div>

        <!-- Submit Button -->
        <div class="col-xl-7 col-12">
            <button [disabled]="!registerFG.valid" type="submit" class="full-width bg-main text-white btn">
                Guardar
            </button>
        </div>
    </div>
</form>