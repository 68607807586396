<section class="container-fluid">
    <div class="row">

        <div *ngIf="userPlan.cancelled_at > 0" class="alert alert-warning col-12" role="alert">
            Has cancelado tu suscripcion, <br>
            recuerda que aun tienes acceso a las terapias que te faltan hasta
            <b> {{userPlan.billing_cycle_end | date: 'shortDate'}} </b>
        </div>

        <div class="jumbotron col-12" [ngClass]="{'sus-border': ('no_plan' == planId && service.user.plan_id == null)}">
            <div class="corner-ribbon top-right blue" *ngIf="'no_plan' == planId && service.user.plan_id == null">Contratado</div>

            <h1 class="display-4 text-center"> Sin Plan </h1>
            <p class="lead  text-center">Paga tu terapia cuando quieras</p>
            <hr class="my-4">
            <p class="lead">
                <a class="btn btn-success btn-lg text-white" (click)="freeSuscription()" role="button">Seleccionar</a>
            </p>
        </div>

        <div class="jumbotron col-12" [ngClass]="{'sus-border': sub.conekta_id == planId || sub.conekta_id == service.user.plan_id?.conekta_id}" *ngFor="let sub of subscriptions">
            <div class="corner-ribbon top-right blue" *ngIf="sub.conekta_id == planId || sub.conekta_id == service.user.plan_id?.conekta_id">Contratado</div>
            <h1 class="display-4 text-center"> ${{(sub.amount/100)}}/ Mensual </h1>
            <p class="lead  text-center">{{sub.name}}</p>
            <hr class="my-4">
            <div class="row">
                <p class="col">
                    <mat-icon class="txt-main">done</mat-icon> {{sub.description}}
                </p>
                <p class="col" *ngIf="sub.conekta_id == planId">
                    <span *ngIf="userPlan.cancelled_at == 0" >
                        Tu próxima factura será: <b> {{userPlan.billing_cycle_end | date: 'shortDate'}} </b>
                    </span>
                </p>
            </div>

            <p class="lead">
                <a class="btn btn-success btn-lg text-white" role="button" (click)="addSubscription(sub)">Contratar</a>
            </p>
        </div>

    </div>
</section>
